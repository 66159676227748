import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform
} from "react-native";
// Customizable Area End

import BlogPostsManagementController, {
  Props,
  configJSON
} from "./BlogPostsManagementController";

export default class BlogPostsManagement extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <View>

      </View>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({

});
// Customizable Area End
