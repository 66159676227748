import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import {runEngine} from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import {} from "./assets";
// Customizable Area End

import {Alert, Dimensions} from "react-native";
import {boolean} from "yup";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showMenu: boolean;
  showModal: boolean;
  apiData: any;
  positions_applied_list: any;
  contactFormData: {
    name: string;
    email: string;
    dob: any;
    position_applied_for: string;
    resumes_attributes: any;
    query: string;
  };
  showCalendar: boolean;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactPageController extends BlockComponent<Props, S, SS> {

  contactPageAPiEndPoint: any;
  getPositionsApiCallId: any;
  postContactPageApiCallId: any;
  fileSelector: any;
  formData: any;

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      showMenu: false,
      showModal: false,
      apiData: null,
      positions_applied_list: [],
      contactFormData: {
        name: '',
        email: '',
        dob: null,
        position_applied_for: '',
        resumes_attributes: '',
        query: ''
      },
      showCalendar: false,
      isLoading: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // selectedDate: any = this.state.contactFormData.dob === null ? new Date() : this.state.contactFormData.dob;


  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.contactPageAPiEndPoint) {
        // local state update
        if(responseJson.data) {
          this.setState({apiData: responseJson.data});
        }
      } else if (apiRequestCallId === this.getPositionsApiCallId) {
        // local state update
        if(responseJson.data) {
          this.setState({positions_applied_list: responseJson.data});
        }
      } else if (apiRequestCallId === this.postContactPageApiCallId) {
        console.log("Inside Correct");
        this.setState({ isLoading: false });
        if (responseJson.message === "Job Application saved succesfully!") {
          alert('Job Application Saved Successfully !');
          this.setState({contactFormData: {dob: null, name: "", position_applied_for: "", resumes_attributes:"", email:"", query: ""}})
        } else {
          alert("Unable to save job application!")
        }
        console.log(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    console.log(Dimensions.get("window").width, Dimensions.get("window").height);
    this.fileSelector = this.buildFileSelector();
    this.getContactPageInfo();
    this.getPositionsInfo();
  }

  getContactPageInfo = () => {

    const header = {
      "Content-Type": configJSON.httpContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactPageAPiEndPoint = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactPageAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPositionsInfo() {

    const header = {
      "Content-Type": configJSON.httpContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPositionsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.positionsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  menuOpenProps = {
    onClick: () => {
      this.setState({showMenu: true});
    }
  }
  menuCloseProps = {
    onPress: () => {
      this.setState({showMenu: false});
    }
  }
  logoProps = {
    onClick: () => {
      this.props.navigation.navigate("Home");
    }
  }

  briefBtnProps = {
    onPress: () => {
      this.setState({showModal: true});
    }
  }
  formCloseProps = {
    onPress: () => {
      this.setState({showModal: false});
    }
  }
  uploadBtnProps = {
    onPress: () => {
      this.fileSelector = this.buildFileSelector();
      this.fileSelector.click();
      this.fileSelector.onchange = (event: any) => {
        this.handleFileChange(event.target.files);
      }

    }
  }

  handleDateClick = () => {
    this.setState((prevState) => ({showCalendar: !prevState.showCalendar}))
  }


  handleFileChange = (files: any) => {
    if (files && files[0]) {
      this.setState((preState: any) => ({
        ...preState,
        contactFormData: {
          ...preState.contactFormData,
          resumes_attributes: files[0]
        }
      }), () => {
        console.log(this.state);
      });
    }
  }

  buildFileSelector = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    fileSelector.setAttribute('multiple', 'multiple');
    return fileSelector;
  }

  canBeSubmitted() {
    const { name, email, dob, position_applied_for, resumes_attributes } = this.state.contactFormData;
    return name.length > 0 && email.length > 0 && dob.length > 0 && position_applied_for.length > 0 && resumes_attributes.length > 0;
  }

  submitContactForm = () => {
    const {name, email, dob, position_applied_for, resumes_attributes, query} = this.state.contactFormData;
    if (name === "" || email === "" || dob === null || position_applied_for === "" || resumes_attributes === "") {
      alert("All fields are mandatory. Please make sure you have filled all the fields and submit again");
      return;
    }
    if (!this.validateEmail(email)) {
      alert("Enter a valid email address");
      return;
    }
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('data[resumes_attributes][0][file]', resumes_attributes, resumes_attributes.name);
    formData.append('data[name]', name);
    formData.append('data[email]', email);
    formData.append('data[dob]', dob);
    formData.append('data[position_applied_for]', position_applied_for);
    formData.append('data[query]', query);

    const header = {};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postContactPageApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.postContactPageApiEndPoint);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigationLinkPressed = (path: string) => {
    let to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), path);
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }

  validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

// Customizable Area End
}

