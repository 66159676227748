import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';

import {
  StyleSheet,
  Text,
  Image,
  ScrollView,
  ImageBackground,
  TouchableOpacity, Dimensions, View, Platform
} from "react-native";
import FilmIntroductionPageController, {
  configJSON,
  Props
} from "./FilmIntroductionPageController";
import {
  menuBackground,
  menuClose,
  menuOpen,
  saintsArtLogo,
  filmProductionBanner
} from "./assets";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class FilmIntroductionPage extends FilmIntroductionPageController {

  static instance: FilmIntroductionPage;

  constructor(props: Props) {
    super(props);
    FilmIntroductionPage.instance = this;
  }

  renderHome = () => {
    const { navigation } = this.props;
    const { apiData } = this.state;
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={filmProductionBanner} style={styles.backImage} resizeMode={"stretch"}>
            <View style={styles.content}>
              <View style={styles.filmLayer}>
                <Text style={styles.film_header_text_one}>FILM</Text>
                <Text style={styles.film_header_text_two}>PRODU-{'\n'}<Text>CTION</Text></Text>
                <Text style={styles.film_text_one}>
                  {apiData?.attributes?.description}
                </Text>
                {/*<TouchableOpacity onPress={() => navigation.navigate("About")}>*/}
                {/*  <Text style={styles.explore}>*/}
                {/*    EXPLORE MORE*/}
                {/*  </Text>*/}
                {/*</TouchableOpacity>*/}
              </View>

            </View>
          </ImageBackground>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>
    )
  }

  render() {
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation} onClose={()=> this.setState({showMenu: false})} />: this.renderHome()
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1,
  },
  backImage: {
    width: wp(100),
    minHeight: windowWidth<600 ? "96vh" : hp(100)
  },
  filmLayer: {
    // marginTop: hp(22),
  },
  film_header_text_one: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(7),
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 1)',
    letterSpacing: hp(0.5)
  },
  film_header_text_two: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(7),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
    letterSpacing: hp(0.5)
  },
  film_text_one: {
    fontFamily: 'Avenir-Book',
    fontSize: hp(3),
    color: configJSON.colorWhite,
    textAlign: "left",
    paddingRight: wp(5),
    marginTop: hp(10),
  },
  textlabel_moreText3: {
    fontWeight: 'bold',
    color: configJSON.colorWhite,
    fontFamily: 'Avenir-Book',
    fontSize: hp(2.5),
    textDecorationLine: "underline",
  },
  content:  {
    position: "absolute",
    right: 0,
    top: hp(22),
    width: wp(28),

  },
  explore: {
    color: configJSON.colorWhite,
    fontFamily: 'Avenir-Book',
    fontSize: hp(2.5),
    textDecorationLine: "underline",
    marginTop: hp(10),

  }
});

// Customizable Area End
