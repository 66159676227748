Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BlogPostsManagement";
exports.labelBodyText = "BlogPostsManagement Body";

exports.httpContentType = "application/json";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.caseStudiesPageAPiEndPoint = "bx_block_blogpostsmanagement/case_studies";
exports.caseStudiesItemPageAPiEndPoint = "bx_block_landingpage/landing_page";
exports.filmIntroductionPageAPiEndPoint = "bx_block_blogpostsmanagement/film_production";
exports.contactPageAPiEndPoint = "bx_block_contactus/contact_us";
exports.positionsApiEndPoint = "bx_block_joblisting/job_list";
exports.postContactPageApiEndPoint = "bx_block_joblisting/job_application";

exports.careersPageAPiEndPoint = "/bx_block_joblisting/career";
exports.jobListApiEndPoint = '/bx_block_joblisting/job_list';

exports.overviewPageApiEndPoint = "bx_block_blogpostsmanagement/pages?name=overview";
exports.brandPhilosophyPageApiEndPoint = "bx_block_blogpostsmanagement/pages?name=brand";
exports.verticalServedPageApiEndPoint = "bx_block_blogpostsmanagement/pages?name=verticals ";

exports.expertisePageAPiEndPoint = "bx_block_blogpostsmanagement/expertise";
exports.prArtPageApiEndPoint = "bx_block_blogpostsmanagement/pages?name=pr%20art";
exports.contentManagementApiEndPoint = "bx_block_blogpostsmanagement/pages?name=content";
exports.strartegicArtPageEndPoint = 'bx_block_blogpostsmanagement/pages?name=strategic%20art'
exports.creativeArtPageEndPoint = 'bx_block_blogpostsmanagement/pages?name=creative'

exports.colorWhite = 'rgba(255,255,255,1)';
exports.colorBlue = 'rgba(2, 14, 53, 1)';
exports.colorMask = 'rgb(216,216,216)';
exports.colorBlack = 'rgba(0,0,0,1)';
exports.colorYellow = 'rgba(248, 222, 65, 1)';
exports.aboutLayer = 'rgba(0, 77, 241, 0.4000000059604645)';
exports.colorPrArtSubHeading = 'rgba(255, 219, 112, 1)';
exports.colorBrown='rgba(53, 33, 44, 1)';
exports.colorBlue = 'rgba(33, 192, 198, 1)';
exports.colorOrange= 'rgba(244, 133, 39, 1)';
exports.formBackground = 'rgba(255, 201, 80, 1)';
exports.colorGrey = '#A1A1A1';
exports.colorGreen = 'rgba(0, 189, 130, 1)';
exports.colorSubHeading = 'rgba(81, 81, 81, 1)'

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
