import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';

import {
  StyleSheet,
  Text,
  Image,
  ScrollView,
  ImageBackground,
  TouchableOpacity, Dimensions, View, TextInput, ActivityIndicator
} from "react-native";
import ContactPageController, {
  configJSON,
  Props
} from "./ContactPageController";
import {
  addressIcon1,
  backgroundContact,
  careersRedBackground, emailIcon, leftPattern,
  menuBackground,
  menuClose,
  menuOpen, mobileIcon, rightPattern,
  saintsArtLogo,
} from "./assets";
import Calendar from 'react-calendar';
import {Icon} from "react-native-elements";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import 'react-calendar/dist/Calendar.css';
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;
const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class ContactPage extends ContactPageController {

  // static instance: ContactPage;

  constructor(props: Props) {
    super(props);
    // ContactPage.instance = this;
  }

  renderHome = () => {
    const {navigation} = this.props;
    const {apiData, positions_applied_list, contactFormData, isLoading} = this.state;
    // const isEnabled = this.canBeSubmitted();
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={backgroundContact} style={styles.backImage} resizeMode={"stretch"}>
            <Image source={leftPattern} style={styles.patternLeft}/>
            <Image source={rightPattern} style={styles.patternRight}/>
            <View style={styles.content}>
              <Text style={styles.hello}>HELLO</Text>
              <Text> </Text>
              <Text style={styles.address}>{apiData?.attributes?.address}</Text>
              {/* <Text style={styles.address}>Sohna Road, Sector 48, Gurugram,</Text>
              <Text style={styles.address}>Haryana, 122018</Text> */}
              <View style={styles.row}>
                <Image source={emailIcon} style={styles.image}/>
                <Text style={styles.email}>{apiData?.attributes?.email}</Text>
                <Image source={mobileIcon} style={styles.image}/>

                <Text
                  style={styles.phone}>{apiData?.attributes?.phone_number_1} | {apiData?.attributes?.phone_number_2}</Text>
              </View>
              <View style={styles.rowAddress}>
                {
                  apiData?.attributes?.offices.length > 0 && apiData?.attributes?.offices.map((obj: any, index: any) =>
                    obj == undefined ? (
                      <Text></Text>
                    ) : (
                      <View style={styles.rowAddr} key={obj.id}>
                        <Image source={addressIcon1} style={styles.addressIcon}/>
                        <View>
                          <Text style={styles.cityName}>{obj.city}</Text>
                          <Text style={styles.handlerEmail}>{obj.email}</Text>
                        </View>
                      </View>
                    )
                  )
                }
              </View>
              <Text style={styles.office}>CORPORATE{'\n'}OFFICE</Text>
              <a href={"https://www.google.com/maps/place/Pearls+Omaxe/@28.6904185,77.1517159,17z/data=!4m5!3m4!1s0x390d023474c0c545:0x4afd988c0eec0f71!8m2!3d28.6906538!4d77.1517803?hl=en-US"} target="_blank">
                <TouchableOpacity>
                  <Text style={styles.explore}>LET'S VISIT</Text>
                </TouchableOpacity>
              </a>
              <TouchableOpacity style={styles.button} {...this.briefBtnProps}>
                <Text style={styles.btnText}>SENT US A BRIEF</Text>
              </TouchableOpacity>
            </View>
          </ImageBackground>
          <AppFooter navigation={navigation}/>
        </ScrollView>
        {this.state.showModal ? <View style={styles.blur}>
          <View style={styles.formContainer}>
            <View style={styles.menu1}>
              <TouchableOpacity {...this.logoProps}>
                <Image source={saintsArtLogo} style={styles.logo}/>
              </TouchableOpacity>
              <TouchableOpacity {...this.formCloseProps} style={{margin: wp(1)}}>
                <Image style={styles.menuOpen} source={menuClose}/>
              </TouchableOpacity>
            </View>
            <div className={"example"}>
              {/*<View style={styles.formBox}>*/}
              <Text style={styles.form}>FORM</Text>
              <Text style={styles.addressForm}>620, 6th Floor, Vipul Trade Center</Text>
              <View style={styles.inputRow}>
                <View style={styles.inputCont}>
                  <TextInput
                    style={styles.input}
                    onSelectionChange={undefined}
                    placeholder={"Enter Name *"}
                    value={contactFormData.name}
                    maxLength={35}
                    onChangeText={(value) => this.setState({contactFormData: {...contactFormData, name: value.trim()}})}
                  />
                </View>
                <View style={styles.inputCont}>
                  <TextInput
                    style={styles.input}
                    placeholder={"Enter Email *"}
                    value={contactFormData.email}
                    maxLength={35}
                    onChangeText={(value) => this.setState({contactFormData: {...contactFormData, email: value.trim()}})}
                  />
                </View>
              </View>
              <View>
                <View style={styles.inputRow}>
                  <TouchableOpacity style={styles.inputCont} onPress={this.handleDateClick}>
                    <Text
                      style={styles.dobText}>{this.state.contactFormData.dob === null ? "DOB" : this.state.contactFormData.dob.toLocaleDateString()}</Text>
                  </TouchableOpacity>
                  <View style={styles.inputCont}>
                    <select
                      value={this.state.contactFormData.position_applied_for}
                      onChange={(event) => this.setState({
                        contactFormData: {
                          ...contactFormData,
                          position_applied_for: event.target.value
                        }
                      })}
                      className={"select"}
                      style={{
                        outline: "none",
                        border: "none",
                        width: "95%",
                        alignSelf: "center",
                        color: "gray",
                        fontSize: hp(2.3)
                      }}>
                      <option value="" disabled selected>Position Applied For</option>
                      {positions_applied_list.length > 0 && positions_applied_list.map((item: any, index: number) => {
                        return (
                          <option value={item.attributes.name}>{item.attributes.name}</option>
                        )
                      })}
                    </select>
                  </View>
                </View>
                {this.state.showCalendar ?
                  <View style={styles.calendarBox}>
                    <Calendar
                      onClickDay={(value, event) => {
                        if (value < new Date())
                          this.setState({showCalendar: false, contactFormData: {...contactFormData, dob: value}});
                        else
                          alert("DOB should not be a future date");
                      }}
                      value={this.state.contactFormData.dob === null ? new Date() : this.state.contactFormData.dob}
                      className={windowWidth<600?"calendarMobile":"calendar"}
                    />
                  </View> : null}
              </View>
              <TouchableOpacity style={styles.upload} {...this.uploadBtnProps}>
                <TextInput
                  style={styles.uploadText}
                  placeholder={"Upload Document *"}
                  value={contactFormData.resumes_attributes['name']}/>
                <Icon name={"attach-file"} type={"material"} color={configJSON.colorGrey} size={wp(2.4)}/>
              </TouchableOpacity>
              <View style={styles.inputRow}>
                <View style={styles.inputCont}>
                  <TextInput
                    style={styles.input}
                    onSelectionChange={undefined}
                    placeholder={"Submit Your Query"}
                    value={contactFormData.query}
                    maxLength={200}
                    onChangeText={(value) => this.setState({contactFormData: {...contactFormData, query: value}})}
                  />
                </View>
              </View>
              <TouchableOpacity disabled={isLoading} style={styles.submit} onPress={() => this.submitContactForm()}>
                {isLoading === true ? (
                  <ActivityIndicator
                    color="#ffffff"
                    size="large"
                  />
                ) : (<Text style={styles.submitText}>Submit</Text>)}
              </TouchableOpacity>
              {/*</View>*/}
            </div>
          </View>
        </View> : null}
      </>
    )
  }

  render() {
    console.log(this.state.showModal);
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation}
                                            onClose={() => this.setState({showMenu: false})}/> : this.renderHome()
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  menu1: {
    flexDirection: "row",
    justifyContent: "space-between",
    // margin: hp(3),
    position: "absolute",
    top: 0,
    left: 0,
    width: wp(100),
  },
  logo: {
    width: wp(18),
    height: hp(6),
    marginTop: hp(2),
    resizeMode: "contain",
    zIndex: 11,
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1,
  },
  backImage: {
    width: wp(100),
    minHeight: windowWidth<600 ? "96vh" : hp(150)
  },
  patternLeft: {
    top: 0,
    left: wp(2),
    position: "absolute",
    width: wp(20),
    height: hp(30),
    resizeMode: "stretch",
  },
  patternRight: {
    top: 0,
    right: 0,
    position: "absolute",
    width: wp(12),
    height: hp(20),
    resizeMode: "stretch",
  },
  hello: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorBlack,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    alignSelf: "flex-end",
    paddingRight: wp(1),
    borderBottomWidth: hp(1),
    borderBottomColor: configJSON.colorBlue,
  },
  content: {
    paddingHorizontal: wp(6),
    marginTop: hp(25),
    paddingLeft: wp(20),
  },
  address: {
    fontSize: wp(2),
    letterSpacing: wp(0.1),
    alignSelf: "flex-end",
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorBlack,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: hp(5),
  },
  rowAddress: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: hp(8),
    flexWrap: "wrap",
  },
  rowAddr: {
    width: wp(26),
    flexDirection: "row",
    alignItems: "center",
    marginTop: hp(5),
  },
  image: {
    width: wp(2),
    height: wp(2),
    resizeMode: "contain",
  },
  email: {
    fontSize: wp(2),
    letterSpacing: wp(0.1),
    alignSelf: "flex-end",
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorBlack,
    marginLeft: wp(1),
    marginRight: wp(2),
  },
  phone: {
    fontSize: wp(2),
    letterSpacing: wp(0.1),
    alignSelf: "flex-end",
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorBlack,
    marginLeft: wp(1),
  },
  addressIcon: {
    width: wp(5),
    height: wp(5),
    resizeMode: "contain",
  },
  cityName: {
    fontSize: wp(2.2),
    letterSpacing: wp(0.1),
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorBlack,
    marginBottom: hp(1),
    fontWeight: "bold",
    marginLeft: wp(1),
  },
  handlerEmail: {
    fontSize: wp(1.4),
    letterSpacing: wp(0.1),
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorBlack,
    marginLeft: wp(1),
  },
  office: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorBlack,
    fontSize: hp(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginTop: hp(12),
    // marginBottom: hp(2),
  },
  explore: {
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorOrange,
    fontSize: hp(3.5),
    textTransform: "uppercase",
    fontWeight: "bold",
    textDecorationLine: "underline",
    letterSpacing: wp(0.1),
    // marginTop: hp(12),
    // marginBottom: hp(2),
  },
  button: {
    paddingHorizontal: wp(4),
    paddingVertical: hp(3.5),
    borderRadius: wp(10),
    backgroundColor: configJSON.colorBlue,
    alignSelf: "flex-end",
    marginVertical: hp(6),
    marginBottom: hp(10),
  },
  btnText: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(3.5),
    textTransform: "uppercase",
    letterSpacing: wp(0.1),
  },
  formContainer: {
    height: hp(100),
    width: wp(100),
    // position: "absolute",
    // top: 0,
    // left: 0,
    // justifyContent: "center",
    alignItems: "center",
    backgroundColor: configJSON.formBackground,
    zIndex: 10,
  },
  formBox: {
    elevation: wp(10),
    width: wp(70),
    height: hp(80),
    backgroundColor: configJSON.colorWhite,
    padding: wp(4),
    overflow: "scroll",
    // borderRadius: wp(1),
  },
  input: {
    height: wp(3),
    //marginHorizontal: wp(1),
    //marginVertical: hp(0.4),
    padding: hp(2),
    borderWidth: 0,
    fontSize: hp(2.5),
    color: 'gray'
  },
  dobText: {
    height: wp(3),
    //marginHorizontal: wp(1),
    //marginVertical: hp(0.4),
    padding: hp(2),
    borderWidth: 0,
    paddingVertical: windowWidth<600 ? wp(0.4): wp(0.8),
    color: "gray",
    fontSize: hp(2.5),
    textAlignVertical: "center"
  },
  inputCont: {
    borderRadius: wp(3),
    borderColor: configJSON.colorGrey,
    borderWidth: wp(0.2),
    // padding: wp(1),
    minWidth: wp(25),
    // width: '35%',
    flex: 1,
    textDecorationStyle: "none",
    margin: wp(1),
    justifyContent: "center",
  },
  inputRow: {
    flexDirection: "row",
    flexWrap: 'wrap',
  },
  inputPicker: {
    height: wp(3),
    marginHorizontal: wp(1),
    marginVertical: hp(0.4),
    borderWidth: 0,
  },
  submit: {
    borderRadius: wp(3),
    padding: wp(1),
    margin: wp(1),
    backgroundColor: configJSON.colorGreen,
    alignItems: "center",
  },
  upload: {
    borderRadius: wp(3),
    borderColor: configJSON.colorGrey,
    borderWidth: wp(0.2),
    padding: wp(1),
    textDecorationStyle: "none",
    margin: wp(1),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: wp(3.5)
  },
  uploadText: {
    color: configJSON.colorGrey,
    width: wp(90),
    fontSize: hp(2.2)
  },
  submitText: {
    color: configJSON.colorWhite,
    // textTransform: "uppercase",
    fontSize: wp(2),
    fontWeight: "bold"
  },
  form: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorGreen,
    fontSize: hp(8),
    textTransform: "uppercase",
  },
  addressForm: {
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorBlack,
    fontSize: hp(3),
    marginBottom: hp(2),
  },
  calendarBox: {
    marginLeft: wp(2),
    // width: wp(20),
  },
  blur: {
    height: "100vh",
    width: "100vw",
    // justifyContent: "center",
    zIndex: 10,
    backgroundColor: "rgba(0,0,0,0.4)"
  }
  // select: {
  //   border:"none",
  //   // fontSize: wp(2),
  // },
});

// Customizable Area End
