import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';
import {
  StyleSheet,
  Text,
  Image,
  View,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
  Dimensions,
  TextInput
} from "react-native";
import CaseStudiesItemPageController, {Props, configJSON} from "./CaseStudiesItemPageController";
// Customizable Area Start
import {
  saintsArtLogo,
  menuOpen,
  menuBackground,
  menuClose,
  caseBanner,
  caseCardOne,
  caseCardTwo,
  caseCardThree, backgroundContact
} from "./assets";
import {Icon} from "react-native-elements";
import {caseItemBackground} from "../../LandingPage/src/assets";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;
// Customizable Area End

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class CaseStudiesItemPage extends CaseStudiesItemPageController {

  constructor(props: Props) {
    super(props);
  }

  renderMain = () => {
    const {caseItem, showImage} = this.state;
    const newsImages = caseItem !== null ? caseItem.attributes.images : null;
    let homeImages: any;
    homeImages = (newsImages && newsImages.length > 3) ? newsImages.splice(0, 3) : newsImages;

    const image = caseItem !== null ? caseItem.attributes.background_image.file.url !== "" ? caseItem.attributes.background_image.file.url : caseItemBackground : caseItemBackground;
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={image} style={styles.caseItemContainer} resizeMode={"stretch"}>
            {/*<View style={styles.caseItemLayer}>*/}
            {/*  <View style={styles.menu}>*/}
            {/*    <TouchableOpacity {...this.logoProps}>*/}
            {/*      <Image source={saintsArtLogo} style={styles.logo}/>*/}
            {/*    </TouchableOpacity>*/}
            {/*    <TouchableOpacity {...this.menuOpenProps}>*/}
            {/*      <Image style={styles.menuOpen} source={menuOpen}/>*/}
            {/*    </TouchableOpacity>*/}
            {/*  </View>*/}
            {/*</View>*/}
            {(caseItem !== null && caseItem !== undefined) ? <View style={styles.container}>
              <Text style={styles.case_item_header_text}>{caseItem.attributes.name}</Text>
              <Text style={styles.case_item_text}>
                {caseItem.attributes.description !== "" ? caseItem.attributes.description :
                  "Ricoh is empowering digital workplaces using innovative technologies and services enabling individuals to" +
                  "work smarter. For more than 80 years, Ricoh has been driving innovation and is a leading provider of" +
                  "document management solutions, IT services, commercial and industrial printing, digital cameras, and" +
                  "industrial systems. Headquartered in Tokyo, Ricoh Group operates in approximately 200 countries and" +
                  "regions." +
                  "In the financial year ended March 2017, Ricoh Group had worldwide sales of A2,028 billion yen (approx.\n" +
                  "18.2" +
                  "billion USD)."}
              </Text>
              <a href="mailto:info@saintsart.in" style={{}}>
                <Text style={styles.case_item_detailed_text}>FOR A DETAILED CASE STUDY, LET'S CONNECT</Text>
              </a>
              {homeImages !== null ? <View style={styles.cardBox}>
                {
                  homeImages.map((item: any, index: number) => {
                    return (
                      <TouchableOpacity onPress={() => {
                        this.setState({showModal: true, selectedImage: index});
                      }} style={styles.imageBox}>
                        <Image source={{uri: item.file.url}} style={styles.cardLayer}/>
                      </TouchableOpacity>
                    )
                  })
                }
              </View> : null}
            </View> : null}
          </ImageBackground>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
        {this.state.showModal ? <View style={styles.formContainer}>
          <View style={styles.menu1}>
            <TouchableOpacity {...this.closeModalProps} style={{margin: wp(1)}}>
              <Image style={styles.menuOpen} source={menuClose}/>
            </TouchableOpacity>
          </View>
          <View style={styles.formBox}>
            <View style={styles.imagePad}>
              <Image source={{uri: newsImages[this.state.selectedImage].file.url}} style={styles.modalImage}/>
            </View>
          </View>
          <View style={styles.sliderRow}>
            <View style={styles.slider}>
              {newsImages.map((item: any, index: number) => {
                return (
                    <View
                      style={[styles.sliderItem, {backgroundColor: index === this.state.selectedImage ? configJSON.colorWhite : configJSON.colorGrey}]}><Text>{" "}</Text></View>
                )
              })}
            </View>
            {newsImages.length>1 ? <View style={styles.controls}>
              <TouchableOpacity style={styles.controlBtn} onPress={this.previous}>
                <Icon name={"arrow-left"} type={"font-awesome"}/>
              </TouchableOpacity>
              <TouchableOpacity style={styles.controlBtn} onPress={this.next}>
                <Icon name={"arrow-right"} type={"font-awesome"}/>
              </TouchableOpacity>
            </View> : null}
          </View>
        </View> : null}
      </>
    )
  }

  render() {
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation} onClose={()=> this.setState({showMenu: false})} />: this.renderMain()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1
  },
  caseItemContainer: {
    width: wp(100),
    minHeight: windowWidth<600 ? "96vh" : hp(100)
  },
  caseItemLayer: {},
  case_item_header_text: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
    textAlign: 'center',
  },
  case_item_text: {
    fontFamily: 'Avenir-Medium',
    fontSize: hp(3.5),
    fontWeight: 'normal',
    color: configJSON.colorWhite,
    padding: wp(3),
    opacity: 1,
    backgroundColor: 'transparent',
    textAlign: 'left',
    //paddingHorizontal: wp(12),
  },
  case_item_detailed_text: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(3),
    fontWeight: 'normal',
    color: configJSON.colorWhite,
    textDecorationLine: "underline",
    textAlign: 'center'
  },
  cardBox: {
    flexDirection: 'row',
    justifyContent: "center",
    marginTop: hp(12),
  },
  imageBox: {
    padding: wp(1),
    borderColor: configJSON.colorWhite,
    borderWidth: 3,
    borderRadius: wp(1.5),
    margin: hp(3),
  },
  cardLayer: {
    width: wp(25),
    height: hp(30),
    resizeMode: "stretch",
    borderRadius: wp(1.5),
  },
  container: {
    paddingHorizontal: wp(12),
    marginTop: windowWidth < 600 ? hp(20) : hp(15),
  },
  formContainer: {
    height: hp(100),
    width: wp(100),
    position: "absolute",
    top: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 15,
  },
  formBox: {
    elevation: wp(10),
    width: wp(50),
    height: hp(50),
    backgroundColor: configJSON.colorWhite,
    padding: wp(1),
    // borderRadius: wp(1),
  },
  menu1: {
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    top: 0,
    left: 0,
    width: wp(100),
  },
  imagePad: {
    padding: wp(1.4),
    width: '100%',
    height: '100%',
    borderWidth: wp(0.2),
    borderColor: configJSON.colorBlack,
  },
  modalImage: {
    width: '100%',
    height: '100%',
    // margin: hp(3),
    resizeMode: "stretch",
  },
  sliderRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: wp(50),
    alignItems: "center",
    marginTop: hp(6),
  },
  slider: {
    width: wp(25),
    flexDirection: "row",
  },
  sliderItem: {
    height: hp(0.3),
    flex: 1,
    flexGrow: 1,
  },
  controls: {
    flexDirection: "row",
    justifyContent: "center",
  },
  controlBtn: {
    width: wp(2.6),
    height: wp(2.6),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: configJSON.colorWhite,
    marginHorizontal: wp(0.5),
  }
});
