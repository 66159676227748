import React from "react";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import VerticalServedController, {Props, configJSON} from "./VerticalServedController";
import {StyleSheet, Text, Image, View, ScrollView, ImageBackground, TouchableOpacity, Dimensions} from "react-native";
// Customizable Area Start
import AppFooter from "../../../components/src/AppFooter";
import {saintsArtLogo, menuOpen, menuBackground, menuClose, verticalBanner} from "./assets";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;
// Customizable Area End

export default class VerticalServedPage extends VerticalServedController {

  static instance: VerticalServedPage;

  constructor(props: Props) {
    super(props);
    VerticalServedPage.instance = this;
  }

  renderMain = () => {
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={verticalBanner} style={styles.verticalContainer} resizeMode={"stretch"}>
            <Text style={styles.vertical_text_one}>Verticals
              <Text style={styles.vertical_text_two}> Served</Text>
            </Text>
            <Text style={styles.vertical_text}>
              {this.state.apiData !== null ? this.state.apiData.attributes.description : ""}
            </Text>
          </ImageBackground>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>
    )
  }

  render() {
    const {navigation} = this.props;
    const _this = this;
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation}
                                            onClose={() => this.setState({showMenu: false})}/> : this.renderMain()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1
  },
  verticalContainer: {
    width: wp(100),
    minHeight: hp(150),
  },
  verticalLayer: {},
  vertical_text_one: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: windowWidth<600 ? hp(7) : hp(12),
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 1)',
    textAlign: 'center',
    letterSpacing: wp(0.3),
    padding: wp(3),
    marginTop: hp(15),
  },
  vertical_text_two: {
    fontFamily: 'Sahar-Heavy',
    fontSize: windowWidth<600 ? hp(7) : hp(12),
    fontWeight: 'bold',
    color: 'rgba(204, 204, 153, 1)',
  },
  vertical_text: {
    fontFamily: 'Avenir-Medium',
    fontSize: hp(3),
    fontWeight: 'normal',
    color: configJSON.colorWhite,
    padding: wp(3),
    opacity: 1,
    backgroundColor: 'transparent',
    textAlign: 'left',
    zIndex: 0,
    paddingHorizontal: wp(12),
    letterSpacing: wp(0.2),
  }
});
// Customizable Area End
