import PropTypes from "prop-types";
import React, {Component} from "react";
import {StyleSheet, Text, View, Image, ImageBackground, Dimensions, TouchableOpacity} from "react-native";
import {BlockComponent} from "../../framework/src/BlockComponent";
import {heightPercentageToDP, widthPercentageToDP} from "react-native-responsive-screen";
import {configJSON} from "../../blocks/BlogPostsManagement/src/CaseStudiesPageController";
import {menuBackground, menuClose, saintsArtLogo} from "../../blocks/BlogPostsManagement/src/assets";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

interface Props {
  navigation: any;
  onClose: any;
}

interface S {
  showAnimation: string;
  opacity: Array<number>;
}

interface SS {
}

const hp = (x: number | string) => {
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class NavigationMenu extends BlockComponent<Props, S, SS> {
  // static propTypes = {
  //   content: PropTypes.string.isRequired
  // };

  constructor(props: Props) {
    super(props);
    this.state = {
      showAnimation: "",
      opacity: [1, 1, 1, 1, 1, 1],
    }
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    setTimeout(() => {
      this.setState({showAnimation: "convert"});
    },);
  }

  closeHandler = () => {
    this.setState({showAnimation: ""});
    setTimeout(() => {
      this.props.onClose()
    }, 800);
  }

  render() {
    const {navigation} = this.props;
    const _this = this;
    return (
      <div className={`menuBox ${this.state.showAnimation}`}>
        <View style={styles.menuBox}>
          <Image style={styles.menuBack} source={require('./assets/group_image.png')}/>
          <View style={styles.menu}>
            <TouchableOpacity onPress={() => {
              navigation.navigate("Home");
              this.props.onClose();
            }}>
              <Image source={require('./assets/skip_Logo.png')} style={styles.logo}/>
            </TouchableOpacity>
            <TouchableOpacity onPress={this.closeHandler}>
              <Image style={styles.menuOpen} source={require('./assets/skip_close.png')}/>
            </TouchableOpacity>
          </View>
          <View style={styles.navBox}>
            <div className="menuItem">
              <TouchableOpacity>
                <Text style={styles.navTitle1} onPress={() => {
                  navigation.navigate("About");
                  this.props.onClose();
                }}>About</Text>
              </TouchableOpacity>
            </div>
            <div className="menuItem">
              <TouchableOpacity>
                <Text style={styles.navTitle} onPress={() => {
                  navigation.navigate("Expertise");
                  this.props.onClose();
                }}>Expertise</Text>
              </TouchableOpacity>
            </div>
            <div className="menuItem">
              <TouchableOpacity>
                <Text style={styles.navTitle1} onPress={() => {
                  navigation.navigate("CaseStudies");
                  this.props.onClose();
                }}>Case Studies</Text>
              </TouchableOpacity>
            </div>
            <div className="menuItem">
              <TouchableOpacity>
                <Text style={styles.navTitle} onPress={() => {
                  navigation.navigate("Careers");
                  this.props.onClose();
                }}>Careers</Text>
              </TouchableOpacity>
            </div>
            <div className="menuItem">

              <TouchableOpacity>
                <Text style={styles.navTitle1} onPress={() => {
                  navigation.navigate("FilmIntroduction");
                  this.props.onClose();
                }}>Film
                  Production</Text>
              </TouchableOpacity>
            </div>
            <div className="menuItem">
              <TouchableOpacity>
                <Text style={styles.navTitle} onPress={() => {
                  navigation.navigate("Contact");
                  this.props.onClose();
                }}>Contact</Text>
              </TouchableOpacity>
            </div>
          </View>
        </View>
      </div>
    )
  }
}

const styles = StyleSheet.create({
  menuBox: {
    height: windowWidth < 600 ? "100vh" : hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: windowWidth < 600 ? hp(12) : hp(7),
    fontWeight: "bold",
    marginVertical: hp(1.4),
    lineHeight: windowWidth < 600 ? hp(20) : hp(7),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: windowWidth < 600 ? hp(12) : hp(7),
    fontWeight: "bold",
    marginVertical: hp(1.4),
    lineHeight: windowWidth < 600 ? hp(20) : hp(7),

  },
});
