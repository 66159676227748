import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';

import {
  StyleSheet,
  Text,
  Image,
  ScrollView,
  ImageBackground,
  TouchableOpacity, Dimensions, View, Platform
} from "react-native";
import ExpertisePageController, {
  configJSON,
  Props
} from "./ExpertisePageController";
import {
  menuBackground,
  menuClose,
  menuOpen,
  saintsArtLogo,
  expertiseTopNav,
  strategicArtBanner,
  prArtBanner,
  contentManagementBanner,
  creativeBanner, dots
} from "./assets";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;


const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class ExpertisePage extends ExpertisePageController {

  static instance: ExpertisePage;

  constructor(props: Props) {
    super(props);
    ExpertisePage.instance = this;
  }

  renderHome = () => {
    const {navigation} = this.props;
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={expertiseTopNav} style={styles.expertiseTopNav} resizeMode={"stretch"}>
            <View style={{flexDirection: "row", alignSelf: "center"}}>
              <View>
                <Text style={styles.expertise_nav_header}>EXPERT</Text>
              </View>
              <View>
                <Text style={styles.expertise_nav_header}>I</Text>
                <Image source={dots} style={styles.dotsImage}/>
              </View>
              <View>
                <Text style={styles.expertise_nav_header}>SE</Text>
              </View>
            </View>
            {/*<Text style={styles.expertise_nav_header}>*/}
            {/*  EXPERT*/}
            {/*<View>*/}
            {/*  <Text>I</Text>*/}
            <Text style={styles.expertise_nav_text}>
              {this.state.apiData ? this.state.apiData.attributes.description : ""}
            </Text>
          </ImageBackground>
          <ImageBackground source={strategicArtBanner} style={styles.strategicArtBanner} resizeMode={"stretch"}>
            <View style={styles.strategicArtBannerPadding}>
              <Text style={styles.strategic_text_one}>STRATEGIC
                <Text style={styles.strategic_text_two}>{'\n'}ART</Text>
              </Text>
              <Text style={styles.strategic_textlabel_moreText3} onPress={() => navigation.navigate("StrategicArt")}>EXPLORE
                MORE</Text>
            </View>
          </ImageBackground>
          <ImageBackground source={prArtBanner} style={styles.prArtBanner} resizeMode={"stretch"}>
            <View>
              <Text style={styles.prart_text_one}>PR
                <Text style={styles.prart_text_two}>{'\n'}ART</Text>
              </Text>
              <Text style={styles.prart_textlabel_moreText3} onPress={() => navigation.navigate("PRArt")}>EXPLORE
                MORE</Text>
            </View>
          </ImageBackground>
          <ImageBackground source={contentManagementBanner} style={styles.contentManagementBanner}
                           resizeMode={"stretch"}>
            <View style={styles.contentManagementHeaderText}>
              <Text style={styles.content_management_text_one}>CONTENT
                <Text style={styles.content_management_text_two}>{'\n'}MANAGEMENT</Text>
              </Text>
            </View>
            <Text style={styles.content_management_textlabel_moreText2}
                  onPress={() => navigation.navigate("ContentManagement")}>EXPLORE MORE</Text>
          </ImageBackground>
          <ImageBackground source={creativeBanner} style={styles.creativeBanner} resizeMode={"stretch"}>
            <Text style={styles.creative_text_one}>{'\n'}CREATIVE</Text>
            <Text style={styles.creative_textlabel_moreText2} onPress={() => navigation.navigate("Creative")}>EXPLORE
              MORE</Text>
          </ImageBackground>
          <AppFooter navigation={navigation}/>
        </ScrollView>
      </>
    )
  }

  render() {
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation}
                                            onClose={() => this.setState({showMenu: false})}/> : this.renderHome()
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1,
  },
  expertiseTopNav: {
    width: wp(100),
    height: hp(60)
  },
  expertiseTopNavLayer: {},
  expertise_nav_header: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
    textAlign: 'center',
    marginTop: windowWidth < 600 ? hp(20) : hp(15),
  },
  expertise_nav_text: {
    fontFamily: 'Avenir-Medium',
    fontSize: hp(3),
    fontWeight: 'normal',
    color: configJSON.colorWhite,
    padding: wp(3),
    opacity: 1,
    backgroundColor: 'transparent',
    textAlign: 'left',
    zIndex: 0,
    paddingHorizontal: wp(12),
  },
  strategicArtBanner: {
    width: wp(100),
    height: hp(60)
  },
  strategicArtBannerPadding: {
    padding: hp(6),
  },
  strategic_text_one: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorBlack,
    textAlign: 'left',
    letterSpacing: hp(0.4),
  },
  strategic_text_two: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorBlack,
  },
  strategic_textlabel_moreText3: {
    opacity: 1,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'bold',
    includeFontPadding: false,
    color: configJSON.colorGrey,
    fontFamily: 'Avenir-Medium',
    fontSize: hp(2.5),
    bottom: -hp(10),
    textAlign: "left",
    textDecorationLine: "underline",
  },
  prArtBanner: {
    width: wp(100),
    height: hp(60),
  },
  prart_text_one: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(24),
    fontWeight: 'bold',
    color: configJSON.colorYellow,
    textAlign: 'center',
    letterSpacing: hp(0.4),
    lineHeight: hp(22),
    marginTop: hp(8),
    marginLeft: wp(20),
  },
  prart_text_two: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(24),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
    marginLeft: wp(28),
  },
  prart_textlabel_moreText3: {
    opacity: 1,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'bold',
    includeFontPadding: false,
    color: configJSON.colorWhite,
    fontFamily: 'Avenir-Book',
    fontSize: hp(2.5),
    bottom: -hp(10),
    textAlign: "center",
    textDecorationLine: "underline",
    marginLeft: hp(-15)
  },
  contentManagementBanner: {
    width: wp(100),
    height: hp(60),
  },
  contentManagementHeaderText: {
    textAlign: 'center',
    alignItems: 'center'
  },
  content_management_text_one: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(14),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
    letterSpacing: hp(2),
    position: 'absolute',
    top: hp(18)
  },
  content_management_text_two: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
  },
  content_management_textlabel_moreText2: {
    opacity: 1,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'bold',
    includeFontPadding: false,
    color: configJSON.colorWhite,
    fontFamily: 'Avenir-Book',
    fontSize: hp(2.5),
    position: 'absolute',
    bottom: 0,
    right: 0,
    textAlign: "right",
    textDecorationLine: "underline",
    padding: hp(6),
  },
  creativeBanner: {
    width: wp(100),
    height: hp(60),
    textAlign: 'center',
    justifyContent: 'center',
  },
  creative_text_one: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(14),
    fontWeight: 'bold',
    color: 'rgba(7, 107, 137, 1)',
    letterSpacing: hp(2),
    padding: hp(3)
  },
  creative_textlabel_moreText2: {
    textDecorationLine: "underline",
    opacity: 1,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'bold',
    includeFontPadding: false,
    color: 'rgba(69, 69, 69, 1)',
    fontFamily: 'Avenir-Book',
    fontSize: hp(2.5),
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: hp(6),
  },
  dotsImage: {
    position: "absolute",
    top: hp(1.5),
    left: wp(0.8),
    width: wp(0.5),
    height: hp(8),
    resizeMode: "stretch",
    marginTop: windowWidth<600 ? hp(20) : hp(15),
  }
});

// Customizable Area End
