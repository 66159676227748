import PropTypes from "prop-types";
import React, {Component} from "react";
import {StyleSheet, Text, View, Image, ImageBackground, Dimensions, TouchableOpacity} from "react-native";
import {BlockComponent} from "../../framework/src/BlockComponent";
import {heightPercentageToDP, widthPercentageToDP} from "react-native-responsive-screen";
import {configJSON} from "../../blocks/BlogPostsManagement/src/CaseStudiesPageController";
import {menuOpen, saintsArtLogo} from "../../blocks/LandingPage/src/assets";


interface Props {
  logoProps?: any;
  navigation: any;
  menuProps? : any;
}

interface S {
}

interface SS {
}

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

export default class Header extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div style={{
        position: "fixed",
        zIndex: 10,
        // backgroundColor: `rgba(157,110,103,${this.state.offset > 0 ? 1 : 0})`,
        width: "100vw",
        backgroundImage: "",
      }} className="nav">
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexGrow: 1,
          marginTop: windowWidth < windowHeight ? "2vh" : "3vh",
          paddingLeft: "4vw",
          paddingRight: "4vw",
          marginBottom: "4vh",
        }}>
          <button {...this.props.logoProps}>
            <img src={saintsArtLogo} style={{height: windowWidth<600 ? "4vh" : "8vh"}}/>
          </button>
          <button {...this.props.menuProps} >
            <img src={menuOpen} style={{height: windowWidth<600 ? "3vh" : "5vh"}}/>
          </button>
        </div>
      </div>
    );
  }
}

