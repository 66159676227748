import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import {runEngine} from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End
import CaseStudiesItemAdapter from "../../adapters/src/CaseStudiesItemAdapter";

import {caseItemBackground} from "../../LandingPage/src/assets";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showMenu: boolean;
    caseStudies: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class CaseStudiesPageController extends BlockComponent<Props, S, SS> {

    getCaseStudiesPageApiCallId: any;

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            showMenu: false,
            caseStudies: null,
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            runEngine.debugLog("API Message Recived", message);

            if (responseJson.data) {
                if (apiRequestCallId === this.getCaseStudiesPageApiCallId) {
                    this.setState({caseStudies: responseJson.data});
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getCaseStudiesPageInfo();
    }

    getCaseStudiesPageInfo() {

        const header = {
            "Content-Type": configJSON.httpContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCaseStudiesPageApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.caseStudiesPageAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    menuOpenProps = {
        onClick: () => {
            this.setState({ showMenu: true });
        }
    }
    menuCloseProps = {
        onPress: () => {
            this.setState({ showMenu: false });
        }
    }
    logoProps = {
        onClick: () => {
            this.props.navigation.navigate("Home");
        }
    }

    navigationLinkPressed = (path: string) => {
        let to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), path);
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(to);
    }

    goToCaseStudy = (item: any) => {
        if (item['attributes']['background_image']['file']['url'] === undefined || item['attributes']['background_image']['file']['url'] == null || item['attributes']['background_image']['file']['url'] == "")
            item['attributes']['background_image']['file']['url'] = caseItemBackground;
        const caseStudyAdapter = new CaseStudiesItemAdapter();
        const msg  = new Message(getName(MessageEnum.NavigationCaseStudyItem));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CaseStudiesItem');
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.Case), item);
        this.send(msg);

        const caseMessage =  new Message((getName(MessageEnum.CaseMessage)));
        caseMessage.addData(getName(MessageEnum.Case), item);
        setTimeout(()=>{
            this.send(caseMessage);
        }, 400)
    }

    // Customizable Area End
}
