import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { } from "./assets";
// Customizable Area End

import { Dimensions } from "react-native";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showMenu: boolean;
    apiData: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class OverviewController extends BlockComponent<Props, S, SS> {

    getOverviewApiCallId: any;

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            showMenu: false,
            apiData: null,
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            runEngine.debugLog("API Message Recived", message);

            if (responseJson.data) {
                if (apiRequestCallId === this.getOverviewApiCallId) {
                    // local state update
                    this.setState({apiData: responseJson.data[0]})
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        console.log(Dimensions.get("window").width, Dimensions.get("window").height);
        this.getOverviewInfo();
    }

    getOverviewInfo() {

        const header = {
            "Content-Type": configJSON.httpContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getOverviewApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.overviewPageApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    menuOpenProps = {
        onClick: () => {
            this.setState({ showMenu: true });
        }
    }
    menuCloseProps = {
        onPress: () => {
            this.setState({ showMenu: false });
        }
    }
    logoProps = {
        onClick: () => {
            this.props.navigation.navigate("Home");
        }
    }

    navigationLinkPressed = (path: string) => {
        let to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), path);
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(to);
    }

    // Customizable Area End
}
