import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';

import {
  StyleSheet,
  Text,
  Image,
  ScrollView,
  ImageBackground,
  TouchableOpacity, Dimensions, View, ActivityIndicator, TextInput,
} from "react-native";
import CareersPageController, {
  configJSON,
  Props
} from "./CareersPageController";
import {
  card1, card2, card3, card4, card5, card6, card7, card8,
  careersBlueBackground,
  menuBackground,
  menuClose,
  menuOpen,
  saintsArtLogo,
} from "./assets";
import { Icon } from "react-native-elements";
import { careersRedBackground } from "./assets";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class CareersPage extends CareersPageController {

  static instance: CareersPage;

  constructor(props: Props) {
    super(props);
    CareersPage.instance = this;
  }

  renderHome = () => {
    const { navigation } = this.props;
    const { positions_applied_list, contactFormData, isLoading } = this.state;
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={careersRedBackground} style={styles.backImage} resizeMode={"stretch"}>
            <View>
              <Text style={styles.heading}>CAREERS</Text>
              <Text style={styles.subHeading1}>WHAT WE ARE LOOKING AT:</Text>
              {(this.state.apiData !== null && this.state.apiData !== []) ?
                <Text style={styles.item}>{this.state.apiData.section_one}</Text>
                : null}
              {/*<Text style={styles.item}>Excellent Communicators</Text>*/}
              {/*<Text style={styles.item}>Independent Thinkers</Text>*/}
              {/*<Text style={styles.item}>Team Players</Text>*/}
              {/*<Text style={styles.item}>Diligent Folks</Text>*/}
              {/*<Text style={styles.item}>Hyper creative minds</Text>*/}
              {/*<Text style={styles.item}>Freshers with a zeal to learn</Text>*/}
            </View>
          </ImageBackground>
          <ImageBackground source={careersBlueBackground} style={styles.backImage1} resizeMode={"stretch"}>
            <View>
              <Text style={styles.subHeading1}>WHAT WE ARE <Text style={{ fontFamily: 'Sahar-Heavy', }}>LOOKING
              AT:</Text></Text>
              {(this.state.apiData !== null && this.state.apiData !== []) ?
                <Text style={styles.item}>{this.state.apiData.section_two}</Text>
                : null}
              {/*<Text style={styles.item}>We believe in hiring "co-workers" not employees.</Text>*/}
              {/*<Text style={styles.item}>Our vision is one. Creating a squad of brilliant communicators.</Text>*/}
              {/*<Text style={styles.item}>OPPORTUNITY TO BE A PART OF DIVERSE & GROUND-BREAKING PROJECTS</Text>*/}
              {/*<Text style={styles.item}>HANDS ON PR EXPERIENCE</Text>*/}
              {/*<Text style={styles.item}>A DOSE OF PROFOUND KNOWLEDGE</Text>*/}
              {/*<Text style={styles.item}>SKILL ENHANCEMENT</Text>*/}
              {/*<Text style={styles.item}>WINGS TO IDEAS</Text>*/}
              {/*<Text style={styles.item}>COMFORT AT WORK</Text>*/}
              {/*<Text style={styles.item}>UNLIMITED ACCESS TO "KADAK CHAI"</Text>*/}
            </View>
          </ImageBackground>
          <View style={styles.jobBox}>
            <View style={styles.jobCol1}>
              <TouchableOpacity {...this.briefBtnProps}>
                <ImageBackground source={card1} style={styles.cardTop}>
                  <Text style={styles.hiring}>We are hiring</Text>
                  <Text
                    style={styles.jobName}>{this.state.jobList !== null ? this.state.jobList[0]?.attributes.name : ""}</Text>
                  <View style={styles.applyBox}>
                    <Text style={styles.apply}>Apply Now</Text>
                    <View style={styles.underline}></View>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
              <TouchableOpacity {...this.briefBtnProps}>
                <ImageBackground source={card2} style={styles.cardBottom}>
                  <Text style={styles.hiring}>We are hiring</Text>
                  <Text
                    style={styles.jobName}>{this.state.jobList !== null ? this.state.jobList[1]?.attributes.name : ""}</Text>
                  <View style={styles.applyBox}>
                    <Text style={styles.apply}>Apply Now</Text>
                    <View style={styles.underline}></View>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
            </View>
            <View style={styles.jobCol2}>
              <TouchableOpacity {...this.briefBtnProps}>
                <ImageBackground source={card3} style={styles.cardTop}>
                  <Text style={styles.hiring}>We are hiring</Text>
                  <Text
                    style={styles.jobName}>{this.state.jobList !== null ? this.state.jobList[2]?.attributes.name : ""}</Text>
                  <View style={styles.applyBox}>
                    <Text style={styles.apply}>Apply Now</Text>
                    <View style={styles.underline}></View>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
              <TouchableOpacity {...this.briefBtnProps}>
                <ImageBackground source={card4} style={styles.cardBottom}>
                  <Text style={styles.hiring}>We are hiring</Text>
                  <Text
                    style={styles.jobName}>{this.state.jobList !== null ? this.state.jobList[3]?.attributes.name : ""}</Text>
                  <View style={styles.applyBox}>
                    <Text style={styles.apply}>Apply Now</Text>
                    <View style={styles.underline}></View>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
            </View>
            <View style={styles.jobCol3}>
              <TouchableOpacity {...this.briefBtnProps}>
                <ImageBackground source={card5} style={styles.cardTop}>
                  <Text style={styles.hiring}>We are hiring</Text>
                  <Text
                    style={styles.jobName}>{this.state.jobList !== null ? this.state.jobList[4]?.attributes.name : ""}</Text>
                  <View style={styles.applyBox}>
                    <Text style={styles.apply}>Apply Now</Text>
                    <View style={styles.underline}></View>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
              <TouchableOpacity {...this.briefBtnProps}>
                <ImageBackground source={card6} style={styles.cardBottom}>
                  <Text style={styles.hiring}>We are hiring</Text>
                  <Text
                    style={styles.jobName}>{this.state.jobList !== null ? this.state.jobList[5]?.attributes.name : ""}</Text>
                  <View style={styles.applyBox}>
                    <Text style={styles.apply}>Apply Now</Text>
                    <View style={styles.underline}></View>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
            </View>
            <View style={styles.jobCol4}>
              <TouchableOpacity {...this.briefBtnProps}>
                <ImageBackground source={card7} style={styles.cardTop}>
                  <Text style={styles.hiring}>We are hiring</Text>
                  <Text
                    style={styles.jobName}>{this.state.jobList !== null ? this.state.jobList[6]?.attributes.name : ""}</Text>
                  <View style={styles.applyBox}>
                    <Text style={styles.apply}>Apply Now</Text>
                    <View style={styles.underline}></View>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
              <TouchableOpacity {...this.briefBtnProps}>
                <ImageBackground source={card8} style={styles.cardBottom}>
                  <Text style={styles.hiring}>We are hiring</Text>
                  <Text
                    style={styles.jobName}>{this.state.jobList !== null ? this.state.jobList[7]?.attributes.name : ""}</Text>
                  <View style={styles.applyBox}>
                    <Text style={styles.apply}>Apply Now</Text>
                    <View style={styles.underline}></View>
                  </View>
                </ImageBackground>
              </TouchableOpacity>
            </View>
          </View>
          <AppFooter navigation={this.props.navigation} />
        </ScrollView>
        {this.state.showModal ? <View style={styles.blur}>
          <View
            style={styles.formContainer1}>
            <View style={styles.menu1}>
              <TouchableOpacity {...this.logoProps}>
                <Image source={saintsArtLogo} style={styles.logo}/>
              </TouchableOpacity>
              <TouchableOpacity {...this.formCloseProps} style={{ margin: wp(1) }}>
                <Image style={styles.menuOpen} source={menuClose} />
              </TouchableOpacity>
            </View>
            <div className={"example"}>
              {/*<View style={styles.formBox}>*/}
              <Text style={styles.form}>FORM</Text>
              <Text style={styles.addressForm}>620, 6th Floor, Vipul Trade Center</Text>
              <View style={styles.inputRow}>
                <View style={styles.inputCont}>
                  <TextInput
                    style={styles.input}
                    onSelectionChange={undefined}
                    placeholder={"Enter Name *"}
                    value={contactFormData.name}
                    maxLength={35}
                    onChangeText={(value) => this.setState({ contactFormData: { ...contactFormData, name: value.trim() } })}
                  />
                </View>
                <View style={styles.inputCont}>
                  <TextInput
                    style={styles.input}
                    placeholder={"Enter Email *"}
                    value={contactFormData.email}
                    maxLength={35}
                    onChangeText={(value) => this.setState({ contactFormData: { ...contactFormData, email: value.trim() } })}
                  />
                </View>
              </View>
              <View>
                <View style={styles.inputRow}>
                  <TouchableOpacity style={styles.inputCont} onPress={this.handleDateClick}>
                    <Text style={styles.dobText}>{this.state.contactFormData.dob === null ? "DOB" : this.state.contactFormData.dob.toLocaleDateString()}</Text>
                  </TouchableOpacity>
                  <View style={styles.inputCont}>
                    <select
                      value={this.state.contactFormData.position_applied_for}
                      className={"select"}
                      onChange={(event) => this.setState({ contactFormData: { ...contactFormData, position_applied_for: event.target.value } })}
                      style={{ outline: "none", border: "none", width: "95%", alignSelf: "center", color: "gray", fontSize: hp(2.3) }} >
                      <option value="" disabled selected>Position Applied For</option>
                      {positions_applied_list.length > 0 && positions_applied_list.map((item: any, index: number) => {
                        return (
                          <option value={item.attributes.name}>{item.attributes.name}</option>
                        )
                      })}
                    </select>
                  </View>
                </View>
                {this.state.showCalendar ? <View style={styles.calendarBox}>
                  <Calendar
                    onClickDay={(value, event) => {
                      if (value < new Date())
                        this.setState({ showCalendar: false, contactFormData: { ...contactFormData, dob: value } });
                      else
                        alert("DOB should not be a future date");
                    }}
                    className={windowWidth<600?"calendarMobile":"calendar"}
                    value={this.state.contactFormData.dob === null ? new Date() : this.state.contactFormData.dob}
                  />
                </View> : null}
              </View>
              <TouchableOpacity style={styles.upload} {...this.uploadBtnProps}>
                <TextInput
                  style={styles.uploadText}
                  placeholder={"Upload Document *"}
                  value={contactFormData.resumes_attributes['name']} />
                <Icon name={"attach-file"} type={"material"} color={configJSON.colorGrey} size={wp(2.4)} />
              </TouchableOpacity>
              <View style={styles.inputRow}>
                <View style={styles.inputCont}>
                  <TextInput
                    style={styles.input}
                    onSelectionChange={undefined}
                    placeholder={"Submit Your Query"}
                    value={contactFormData.query}
                    maxLength={200}
                    onChangeText={(value) => this.setState({ contactFormData: { ...contactFormData, query: value } })}
                  />
                </View>
              </View>
              <TouchableOpacity disabled={isLoading} style={styles.submit} onPress={() => this.submitContactForm()}>
                {isLoading === true ? (
                  <ActivityIndicator
                    color="#ffffff"
                    size="large"
                  />
                ) : (<Text style={styles.submitText}>Submit</Text>)}
              </TouchableOpacity>
              {/*</View>*/}
            </div>
          </View>
        </View> : null}
      </>
    )
  }

  render() {
    console.log(this.state.showModal);
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation}
        onClose={() => this.setState({ showMenu: false })} /> : this.renderHome()
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    marginTop: hp(2),
    width: wp(18),
    height: hp(6),
    resizeMode: "contain",
    zIndex: 11,
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1,
  },
  heading: {
    marginTop: windowWidth<600 ? hp(18) : hp(12),
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: wp(8),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
    textAlign: "center"
  },
  backImage: {
    width: wp(100),
    height: hp(100),
    resizeMode: "contain",
  },
  backImage1: {
    width: wp(100),
    height: hp(100),
    resizeMode: "contain",
    paddingVertical: hp(10),
  },
  subHeading: {
    alignSelf: "center",
    color: configJSON.colorWhite,
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  subHeading1: {
    alignSelf: "center",
    color: configJSON.colorWhite,
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  item: {
    alignSelf: "center",
    color: configJSON.colorWhite,
    fontSize: hp(4),
    fontFamily: 'Sahar-Heavy-Outline',
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
    lineHeight: hp(7),
    textAlign: "center",
  },
  formContainer: {
    height: hp(50),
    width: wp(50),
    backgroundColor: configJSON.colorWhite,
  },
  jobBox: {
    width: wp(100),
    height: hp(100),
    paddingHorizontal: wp(5),
    flexDirection: "row",
    alignSelf: "center",
    backgroundColor: '#F5F5F5',
    overflow: "hidden",
    justifyContent: "center",
  },
  jobCol1: {
    marginTop: hp(20),
  },
  jobCol2: {},
  jobCol3: {
    marginTop: -hp(20),
  },
  jobCol4: {
    marginTop: hp(10),
  },
  hiring: {
    fontFamily: "Avenir-Medium",
    fontSize: hp(2),
    fontWeight: "bold",
    color: configJSON.colorBlue
  },
  jobName: {
    fontSize: hp(4),
    fontFamily: 'Sahar-Heavy',
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  cardTop: {
    width: wp(18),
    height: hp(48),
    marginHorizontal: wp(1),
    marginBottom: hp(2),
    padding: wp(1),
    justifyContent: "center",
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderColor: configJSON.colorWhite,
  },
  cardBottom: {
    width: wp(18),
    height: hp(48),
    marginHorizontal: wp(1),
    padding: wp(1),
    justifyContent: "center",
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderColor: configJSON.colorWhite,
    borderTopWidth: 2,
    borderBottomWidth: 2,
  },
  menu1: {
    flexDirection: "row",
    justifyContent: "space-between",
    // margin: hp(3),
    position: "absolute",
    top: 0,
    left: 0,
    width: wp(100),
  },
  formContainer1: {
    height: hp(100),
    width: wp(100),
    // position: "absolute",
    // top: 0,
    // left: 0,
    // justifyContent: "center",
    alignItems: "center",
    backgroundColor: configJSON.formBackground,
    zIndex: 101,
  },
  formBox: {
    elevation: wp(10),
    width: wp(70),
    height: hp(80),
    backgroundColor: configJSON.colorWhite,
    padding: wp(4),
    overflow: "scroll"
    // borderRadius: wp(1),
  },
  form: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorGreen,
    fontSize: hp(8),
    textTransform: "uppercase",
  },
  addressForm: {
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorBlack,
    fontSize: hp(3),
    marginBottom: hp(2),
  },
  inputRow: {
    flexDirection: "row",
    flexWrap: 'wrap',
  },
  inputCont: {
    borderRadius: wp(3),
    borderColor: configJSON.colorGrey,
    borderWidth: wp(0.2),
    // padding: wp(1),
    minWidth: wp(25),
    // width: '35%',
    flex: 1,
    textDecorationStyle: "none",
    margin: wp(1),
    justifyContent: "center",
  },
  input: {
    height: wp(3),
    //marginHorizontal: wp(1),
    //marginVertical: hp(0.4),
    padding: hp(2),
    borderWidth: 0,
    fontSize: hp(2.5),
    color: 'gray'
  },
  dobText: {
    height: wp(3),
    //marginHorizontal: wp(1),
    //marginVertical: hp(0.4),
    padding: hp(2),
    borderWidth: 0,
    paddingVertical: windowWidth<600 ? wp(0.4): wp(0.8),
    color: "gray",
    fontSize: hp(2.5)
  },
  calendarBox: {
    marginLeft: wp(2),
    // width: wp(20),
  },
  upload: {
    borderRadius: wp(3),
    borderColor: configJSON.colorGrey,
    borderWidth: wp(0.2),
    padding: wp(1),
    textDecorationStyle: "none",
    margin: wp(1),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: wp(3.5)
  },
  uploadText: {
    color: configJSON.colorGrey,
    width: wp(90),
    fontSize: hp(2.2)
  },
  submit: {
    borderRadius: wp(3),
    padding: wp(1),
    margin: wp(1),
    backgroundColor: configJSON.colorGreen,
    alignItems: "center",
  },
  submitText: {
    color: configJSON.colorWhite,
    // textTransform: "uppercase",
    fontSize: wp(2),
    fontWeight: "bold",
  },
  applyBox: {
    position: "absolute",
    bottom: 0,
    marginBottom: hp(3),
    alignSelf: "center",
    justifyContent: "center"
  },
  apply: {
    fontFamily: "Avenir-Medium",
    fontSize: hp(2),
    fontWeight: "bold",
    color: configJSON.colorBlack,
    marginBottom: hp(1),
  },
  underline: {
    // textDecorationLine:"underline",
    backgroundColor: configJSON.colorBlue,
    height: 4,
    width: wp(4),
    alignSelf: "center",
  },
  blur: {
    height: "100vh",
      width: "100vw",
      // justifyContent: "center",
      zIndex: 12,
      backgroundColor: "rgba(0,0,0,0.3)"
  }
});

// Customizable Area End
