// App.js - WEB
import React, {Component, useEffect} from "react";
import {View} from "react-native";
import firebase from 'firebase'
import {connect} from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import {ModalContainer} from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BlogPostsManagement from "../../blocks/BlogPostsManagement/src/BlogPostsManagement";
import SocialMediaAccountRegistrationScreen
  from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import MixpanelIntegration from "../../blocks/MixpanelIntegration/src/MixpanelIntegration";
import LandingPage from "../../blocks/LandingPage/src/LandingPage";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Share from "../../blocks/Share/src/Share";
import JobListing from "../../blocks/JobListing/src/JobListing";

import About from '../../blocks/BlogPostsManagement/src/AboutPage';
import Overview from '../../blocks/BlogPostsManagement/src/Overview';
import PRArt from '../../blocks/BlogPostsManagement/src/PRArt';
import VerticalServed from '../../blocks/BlogPostsManagement/src/VerticalServed';
import BrandPhilosophy from '../../blocks/BlogPostsManagement/src/BrandPhilosophy.tsx';

import Expertise from '../../blocks/BlogPostsManagement/src/ExpertisePage';
import StrategicArt from '../../blocks/BlogPostsManagement/src/StrategicArt';
import ContentManagement from '../../blocks/BlogPostsManagement/src/ContentManagement';
import CaseStudies from '../../blocks/BlogPostsManagement/src/CaseStudiesPage';
import CaseStudiesItem from '../../blocks/BlogPostsManagement/src/CaseStudiesItemPage';
import Careers from '../../blocks/BlogPostsManagement/src/CareersPage';
import FilmIntroduction from '../../blocks/BlogPostsManagement/src/FilmIntroductionPage';
import Contact from '../../blocks/BlogPostsManagement/src/ContactPage';
import Creative from "../../blocks/BlogPostsManagement/src/Creative";

const routeMap = {
  BlogPostsManagement: {
    component: BlogPostsManagement,
    path: "/BlogPostsManagement"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  MixpanelIntegration: {
    component: MixpanelIntegration,
    path: "/MixpanelIntegration"
  },
  // LandingPage: {
  //   component: LandingPage,
  //   path: "/LandingPage"
  // },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  JobListing: {
    component: JobListing,
    path: "/JobListing"
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  About: {
    component: About,
    path: "/About"
  },
  Expertise: {
    component: Expertise,
    path: "/Expertise"
  },
  CaseStudies: {
    component: CaseStudies,
    path: "/CaseStudies"
  },
  Careers: {
    component: Careers,
    path: "/Careers"
  },
  FilmIntroduction: {
    component: FilmIntroduction,
    path: "/FilmIntroduction"
  },
  Contact: {
    component: Contact,
    path: "/Contact"
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Overview: {
    component: Overview,
    path: "/Overview"
  },
  BrandPhilosophy: {
    component: BrandPhilosophy,
    path: "/BrandPhilosophy"
  },
  PRArt: {
    component: PRArt,
    path: "/PRArt"
  },
  VerticalServed: {
    component: VerticalServed,
    path: "/VerticalServed"
  },
  StrategicArt: {
    component: StrategicArt,
    path: "/StrategicArt"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  Creative: {
    component: Creative,
    path: "/Creative"
  },
  CaseStudiesItem: {
    component: CaseStudiesItem,
    path: "/CaseStudiesItem"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <>
        <style type="text/css">{`
          @font-face {
            font-family: 'MaterialIcons';
            src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
          }
          @font-face {
            font-family: 'FontAwesome';
            src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
          }
        `}</style>
        <View style={{height: '100vh', width: '100vw'}}>
          {/*<TopNav />*/}
          {WebRoutesGenerator({routeMap})}
          <ModalContainer/>
        </View>
      </>
    );
  }
}

export default App;
