import PropTypes from "prop-types";
import React, {Component} from "react";
import {StyleSheet, Text, View, Image, ImageBackground, Dimensions, TouchableOpacity} from "react-native";
import {BlockComponent} from "../../framework/src/BlockComponent";
import {heightPercentageToDP, widthPercentageToDP} from "react-native-responsive-screen";
import {configJSON} from "../../blocks/BlogPostsManagement/src/CaseStudiesPageController";

interface Props {
  showSocial?: boolean;
  navigation: any
}

interface S {
}

interface SS {
}

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class AppFooter extends BlockComponent<Props, S, SS> {
  // static propTypes = {
  //   content: PropTypes.string.isRequired
  // };

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // <ImageBackground source={require('./assets/view_bg_footer.png')} style={styles.container}>
      <View style={styles.container}>
        <View>
          <Text style={styles.text}>info@saintsart.in{"\n"}+91 9711455292{"\n"}+91 9999321561</Text>
        </View>
        <View style={styles.icons}>
          <TouchableOpacity onPress={() => this.props.navigation.navigate("Home")}>
            <Image source={require("./assets/skip_footer logo.png")} style={styles.logoFooter}/>
          </TouchableOpacity>
          {this.props.showSocial ? <View style={styles.social}>
            <Image source={require("./assets/skip_facebook.png")} style={styles.socialIcon}/>
            <Image source={require("./assets/skip_instagram.png")} style={styles.socialIcon}/>
            <Image source={require("./assets/skip_twitter.png")} style={styles.socialIcon}/>
          </View> : null}
        </View>
        <View>
          <Text style={styles.text}>© Copyright 2021,{"\n"}All rights reserved{"\n"}With Saints Art</Text>
        </View>
      </View>
      // </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: wp(4),
    paddingVertical: hp(2),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(22,39,99,255)",
  },
  icons: {
    justifyContent: "center",
    alignItems: "center",
  },
  logoFooter: {
    width: wp(15),
    height: wp(4.5),
    resizeMode: "stretch",
  },
  social: {
    flexDirection: "row",
    marginTop: hp(2),
  },
  socialIcon: {
    width: wp(3),
    height: wp(1.8),
    resizeMode: "contain",
    marginHorizontal: wp(0.2),
  },
  text: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(2),
    textTransform: "uppercase",
  }
});
