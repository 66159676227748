import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';

import {
  StyleSheet,
  Text,
  Image,
  ScrollView,
  ImageBackground,
  TouchableOpacity, Dimensions, View, Platform
} from "react-native";
import LandingPageController, {
  configJSON,
  Props
} from "./CreativeController";
import {
  creativeBackground,
  menuBackground,
  menuClose,
  menuOpen,
  saintsArtLogo, StrategicArtBackground,
} from "./assets";
import CreativeController from "./CreativeController";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class Creative extends CreativeController {


  constructor(props: Props) {
    super(props);
  }

  renderHome = () => {
    const {navigation} = this.props;
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={creativeBackground} style={styles.backImage} resizeMode={"stretch"}>
            <View>
              <Text style={styles.heading}>CREATIVE</Text>
            </View>
            <View style={styles.contentBox}>

              {/*<Text style={styles.content}>*/}
              {/*  {'\n'}We as brand experts have been managing every aspect of brand transformation & establishment right*/}
              {/*  from creating the brand Identity to Brand Audit. Our valued internal statistics helps corporations align*/}
              {/*  results through innovative and presentable management of internal & external communication i.e. Brand*/}
              {/*  Logo, Tagline, Mission Statement, Advertisements, Newsletters, White Papers, Brochure, E-mailers,*/}
              {/*  Campaigns & Digital Strategy{'\n'}{'\n'}Creative development is our forte as our approach has been and*/}
              {/*  continues to be “out-of-the-box”. The team’s expertise, repertoire and robust technical knowledge take us*/}
              {/*  a notch higher from to other creative hot shops. When it comes to creating the most terrific and unique*/}
              {/*  concept and designs, we leave no stone unturned.{'\n'}{'\n'}*/}
              {/*</Text>*/}

              <Text style={styles.content}>
                {(this.state.apiData!==null && this.state.apiData!==[]) ? this.state.apiData.attributes.description : null}
              </Text>

              <Text style={styles.subHeading}>Our creative capabilities includes:</Text>

              <View style={styles.cap}>
                <Text style={styles.capItem}>Social Media</Text>
                <Text style={styles.capItem}>|</Text>
                <Text style={styles.capItem}>Brand Campaigns</Text>
                <Text style={styles.capItem}>|</Text>
                <Text style={styles.capItem}>Print Media</Text>
              </View>
              <View style={styles.cap}>
                <Text style={styles.capItem}>Packaging & Event Design</Text>
                <Text style={styles.capItem}>|</Text>
                <Text style={styles.capItem}>Custom Design & Development</Text>
              </View>
              <View style={styles.cap}>
                <Text style={styles.capItem}>Brand Revamp</Text>
              </View>

            </View>
          </ImageBackground>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>
    )
  }


  render() {
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation} onClose={()=> this.setState({showMenu: false})} />: this.renderHome()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  text: {
    fontSize: wp(1),
    height: hp(60),
  },
  backImage: {
    width: wp(100),
    minHeight: windowWidth<600 ? "96vh" : hp(150),
    resizeMode: "contain",
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  heading: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: windowWidth<6000 ? hp(12) : 108,
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
    textAlign: "center",
    marginTop: windowWidth < 600 ? hp(20) : hp(15),
  },
  heading1: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: wp(8),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
    textAlign: "center",
  },
  contentBox: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: wp(12),
    paddingBottom: hp(20),
  },
  content: {
    fontSize: hp(3),
    color: configJSON.colorWhite,
    fontFamily: "Avenir-Medium",
    letterSpacing: wp(0.1),
    textAlign: "center",
    marginBottom: hp(6),
    lineHeight: hp(6),
  },
  cap: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: wp(76),
    marginBottom: hp(4),
  },
  capItem: {
    fontSize: wp(2.4),
    color: configJSON.colorWhite,
    textAlign: "center",
    marginBottom: hp(1.5),
    fontFamily: "Avenir-Medium",
  },
  subHeading: {
    fontSize: wp(2.4),
    color: configJSON.colorWhite,
    textAlign: "center",
    marginTop: hp(6),
    marginBottom: hp(8),
    fontWeight: "bold",
    fontFamily: "Avenir-Medium"
  }
});
// Customizable Area End
