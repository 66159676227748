import React from "react";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import OverviewController, {Props, configJSON} from "./OverviewController";
import {StyleSheet, Text, Image, View, ScrollView, ImageBackground, TouchableOpacity, Dimensions} from "react-native";
// Customizable Area Start
import AppFooter from "../../../components/src/AppFooter";
import {saintsArtLogo, menuOpen, menuBackground, menuClose, imgOverBG, imgCircleOne, imgCircleTwo} from "./assets";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;
// Customizable Area End

export default class OverviewPage extends OverviewController {

  static instance: OverviewPage;

  constructor(props: Props) {
    super(props);
    OverviewPage.instance = this;
  }

  renderMain = () => {
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={imgOverBG} style={styles.overviewContainer}>
            <Text style={styles.overview_text_one}>OVER
              <Text style={styles.overview_text_two}>{'\n'}VIEW</Text>
            </Text>
          </ImageBackground>
          <View style={styles.overviewContainerTwo}>
            <Image source={imgCircleOne} style={styles.view_circle}/>
            <Image source={imgCircleTwo} style={styles.view_circle1}/>
            <Text style={styles.overview_container_two_text}>
              {this.state.apiData!==null ? this.state.apiData.attributes.description : ""}
            </Text>
          </View>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>
    )
  }

  render() {
    const {navigation} = this.props;
    const _this = this;
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation} onClose={()=> this.setState({showMenu: false})} />: this.renderMain()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1
  },
  overviewContainer: {
    width: wp(100),
    height: hp(50),
    resizeMode: "contain"
  },
  overviewLayer: {},
  overview_text_one: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
    textAlign: 'center',
    marginTop: windowWidth < 600 ? hp(12) : hp(15),
  },
  overview_text_two: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
  },
  overviewContainerTwo: {
    backgroundColor: 'rgba(14, 60, 143, 1)',
    width: wp(100),
    minHeight:  windowWidth < 600? "50vh" : hp(50),
    padding: hp(2),
  },
  view_circle: {
    height: wp(6),
    width: wp(6),
    resizeMode: "contain",
    position: 'absolute',
    right: 0,
    top: wp(2),
  },
  view_circle1: {
    height: hp(40),
    width: wp(8),
    resizeMode: "contain",
    position: 'absolute',
    left: 0,
    bottom: wp(1)
  },
  overview_container_two_text: {
    fontFamily: 'Avenir-Medium',
    fontSize: hp(3),
    fontWeight: 'normal',
    color: configJSON.colorWhite,
    padding: wp(3),
    opacity: 1,
    backgroundColor: 'transparent',
    textAlign: 'left',
    zIndex: 0,
    paddingHorizontal: wp(12),
    letterSpacing: wp(0.2),
  }
});
// Customizable Area End
