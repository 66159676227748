
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import {runEngine} from "../../../framework/src/RunEngine";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import CaseStudiesItemAdapter from "../../adapters/src/CaseStudiesItemAdapter";
// Customizable Area Start
import {imgPasswordInVisible, imgPasswordVisible} from "./assets";
// Customizable Area End

import {Dimensions} from "react-native";
import {createRef} from "react";
import {heightPercentageToDP} from "react-native-responsive-screen";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  landing_page_data: any;
  showMenu: boolean;
  toolsStatus: Array<boolean>;
  offset: number;
  showHeading: boolean;
  showSubHeading: boolean;
  animateCover: boolean;
  positionFixed: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
}

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

export default class LandingPageController extends BlockComponent<Props, S, SS> {

  getLandingPageApiCallId: any;
  myRef: any;
  topPosition: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.myRef = createRef();
    this.topPosition = this.myRef?.current?.getBoundingClientRect().top;

    this.state = {
      landing_page_data: null,
      showMenu: false,
      toolsStatus: [],
      offset: 0,
      showHeading: false,
      showSubHeading: false,
      animateCover: false,
      positionFixed: true,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  scrollRef: any = createRef();


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.getLandingPageApiCallId) {
          const strategicTools = responseJson.data.attributes.strategic_tools.data;
          let status: Array<boolean> = [];
          strategicTools.forEach(() => {
            status.push(false);
          })
          this.setState({
            landing_page_data: responseJson.data,
            toolsStatus: status,
          });
        }
      }
    }
    // Customizable Area End
  }

  leftClick = {
    onPress: () => {
      let temp = this.state.offset - 200 > 0 ? this.state.offset - 200 : 0
      this.scrollRef.current.scrollTo({
        x: temp,
        animated: true,
      });
      this.setState((prevState) => ({offset: temp}))
    }
  }

  rightClick = {
    onPress: () => {
      this.scrollRef.current.scrollTo({
        x: this.state.offset + 200,
        animated: true,
      });
      this.setState((prevState) => ({offset: prevState.offset + 200}))
    }
  }

  // Customizable Area Start
  scrollHandler = () => {
    // console.log(window.scrollY);
    if (window.scrollY > 360) {
      this.setState({showSubHeading: true});
    } else {
      this.setState({showSubHeading: false});
    }
    if(this.state.positionFixed) {
      this.performAnimation();
      window.scrollTo(0,0);
    }
    // window.scrollTo(0,0);
    console.log("ASdf");
  }

  performAnimation() {
    if(window.sessionStorage.getItem("firstLoad") === null) {
      setTimeout(() => {
        this.setState({animateCover: true});
      }, 0);
      setTimeout(() => {
        this.setState({positionFixed: false});
        window.sessionStorage.setItem("firstLoad", "true");
      }, 2500);
    } else {
      console.log("Error Here!");
      this.setState({positionFixed: false});
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    console.log(Dimensions.get("window").width, Dimensions.get("window").height);
    this.getLandingPageInfo();
    this.setState({showHeading: true});
    window.addEventListener("scroll", this.scrollHandler);
  }

  async componentWillUnmount(): Promise<void> {
    super.componentWillUnmount();
    window.removeEventListener("scroll", this.scrollHandler)
  }

  getLandingPageInfo() {

    const header = {
      "Content-Type": configJSON.httpContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLandingPageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  menuOpenProps = {
    onClick: () => {
      this.setState({showMenu: true});
    }
  }
  menuCloseProps = {
    onPress: () => {
      this.setState({showMenu: false});
    }
  }
  logoProps = {
    onClick: () => {
      this.props.navigation.navigate("Home");
    }
  }

  navigationLinkPressed = (path: string) => {
    let to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), path);
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }

  updateStatus = (index: number) => {
    let array = [...this.state.toolsStatus];
    array[index] = !array[index];
    this.setState({toolsStatus: array});
  }

  goToCaseStudy = (item: any) => {
    item['attributes']['background_image'] = item['attributes']['images'];
    item['attributes']['images'] = [item['attributes']['images']];
    const caseStudyAdapter = new CaseStudiesItemAdapter();
    const msg = new Message(getName(MessageEnum.NavigationCaseStudyItem));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CaseStudiesItem');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.Case), item);
    this.send(msg);

    const caseMessage = new Message((getName(MessageEnum.CaseMessage)));
    caseMessage.addData(getName(MessageEnum.Case), item);
    setTimeout(() => {
      this.send(caseMessage);
    }, 400)
  }


  // Customizable Area End
}



