import React, {createRef} from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';

import {
  StyleSheet,
  Text,
  Image,
  ScrollView,
  ImageBackground,
  TouchableOpacity, Dimensions, View, Platform
} from "react-native";
import LandingPageController, {
  configJSON,
  Props, S, SS
} from "./LandingPageController";
import {
  arrowIcon,
  blueBackground,
  caseItemBackground,
  caseStudyBackground, leftArrow, logo,
  menuBackground,
  menuClose,
  menuOpen, rightArrow,
  saintsArtLogo,
  toolCard,
  topNavBackground,
  verticalBackground
} from "./assets";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import ScrollAnimation from 'react-animate-on-scroll';

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class LandingPage extends LandingPageController {

  constructor(props: Props) {
    super(props);
  }

  renderHome = () => {
    // console.log(window.scrollY);
    const {landing_page_data} = this.state;
    const {navigation} = this.props;
    const welcomeDescription = landing_page_data ? landing_page_data.attributes.welcome_description : null;
    const letter1 = welcomeDescription ? welcomeDescription.substr(0, 1) : null;
    const restDescription = welcomeDescription ? welcomeDescription.substr(1, welcomeDescription.length - 1) : null;
    // const alpha = this.state.offset / 600;
    // console.log(this.state.offset, alpha);
    const height = 100 - (43 / 200) * window.scrollY;
    const width = 70 - (56 / 200) * window.scrollY;


    return (
      <div>

        <div className={this.state.animateCover ? "logoCover fadeOut" :"logoCover"}
             style={{display: window.sessionStorage.getItem("firstLoad") !== "true"? "flex": "none"}}>
          <img src={logo} className={"initialLogo"} />
        </div>

        <div style={{
          position: "fixed",
          zIndex: 10,
          // backgroundColor: `rgba(157,110,103,${this.state.offset > 0 ? 1 : 0})`,
          width: "100vw",
          backgroundImage: window.scrollY>0? "linear-gradient(to right, rgba(19,38,98,255), rgba(116,79,92,255)": "",
        }} className="nav">
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexGrow: 1,
            // marginTop: "3vh",
            paddingLeft: "3vw",
            paddingRight: "4vw",
            marginBottom: this.state.showSubHeading ? "5px" : "12px",
          }}>
            <button>
              {/*<img src={saintsArtLogo} className="logo" style={{*/}
              {/*  height: window.scrollY > 300 ? "7vh" : "50vh",*/}
              {/*  width: window.scrollY > 300 ? "14vw" : "70vw",*/}
              {/*}}/>*/}
              <img src={logo} className="logo" style={{
                height: `7vh`,
                width: `14vw`,
              }}/>
            </button>
            <button {...this.menuOpenProps} style={{paddingTop: "3vh"}}>
              <img src={menuOpen}/>
            </button>
          </div>
          <div className="subHeading" style={{
            opacity: this.state.showSubHeading ? 1 : 0,
            display: this.state.showSubHeading ? "block" : "none"
          }}>
            <p>Enabling Communication between brands & consumers for over a decade</p>
          </div>
        </div>

        <div className={""}>

        </div>

        {/*<div style={{position: "fixed", zIndex: 10, backgroundColor: `rgba(0,0,0,${this.state.offset > 0 ? 1 : 0})`}}>*/}
        {/*<View style={styles.menuX}>*/}
        {/*  <TouchableOpacity {...this.logoProps}>*/}
        {/*    <Image source={saintsArtLogo} style={this.state.offset>0 ? styles.logo : styles.logoLarge}/>*/}
        {/*  </TouchableOpacity>*/}
        {/*  <TouchableOpacity {...this.menuOpenProps}>*/}
        {/*    <Image style={styles.menuOpen} source={menuOpen}/>*/}
        {/*  </TouchableOpacity>*/}
        {/*</View>*/}
        {/*</div>*/}
        <div style={{
          overflow: this.state.positionFixed? "hidden": "scroll",
          height: this.state.positionFixed?"100.1vh": "",
          width: this.state.positionFixed?"100vw":"",
        }}>
          <div className={"container"}
               style={{
                 backgroundImage: `url(${topNavBackground})`,
                 height: "100vh",
                 width: "100vw",
                 backgroundSize: "cover",
               }}>
            {/*<div className={"deliver"} style={{left: this.state.showHeading ? "4vw" : -200}} ref={this.myRef}>*/}
            {/*  <p style={{*/}
            {/*    fontFamily: 'Sahar-Heavy-Outline',*/}
            {/*    fontWeight: "bold",*/}
            {/*    fontSize: hp(9),*/}
            {/*    letterSpacing: hp(0.4),*/}
            {/*    color: 'rgba(255, 255, 255, 1)',*/}
            {/*  }}>We<br/>Deliver</p>*/}
            {/*</div>*/}
            <div className={"enable"} style={{right: this.state.showHeading ? "4vw" : -200}}>
              <p style={{
                fontFamily: 'Sahar-Heavy',
                fontSize: hp(8),
                fontWeight: 'bold',
                color: 'rgba(255, 255, 255, 1)',
              }}>Enabling Communication<br/>between brands & consumers<br/>for over a
                decade</p>
            </div>
          </div>

          <ScrollView style={styles.root}>
            {/*<ImageBackground source={topNavBackground} style={styles.backImage}>*/}
            {/*  <View style={styles.blueLayer}>*/}
            {/*    <Text style={styles.deliver}>We{'\n'}Deliver</Text>*/}
            {/*    <Text style={styles.enable}>Enabling Communication{'\n'}between brands & consumers{'\n'}for over a*/}
            {/*      decade</Text>*/}
            {/*  </View>*/}
            {/*</ImageBackground>*/}

            {/*About Saints Art*/}
            <View style={styles.about}>
              <View style={styles.box}>
                <View style={styles.welcome}>
                  <Text style={styles.welcomeText1}>WELCOME <Text style={styles.welcomeText2}>TO SAINTS
                    ART</Text></Text>

                </View>
                <View style={styles.description}>
                  {welcomeDescription ? <Text style={styles.attrbuted_textlabel_paraText}>{letter1}
                    <Text style={styles.attrbuted_textlabel_paraText2}>{restDescription}
                    </Text>
                  </Text> : null}
                  <Text style={styles.textlabel_moreText3} onPress={() => navigation.navigate("About")}>EXPLORE
                    MORE</Text>
                </View>
              </View>
            </View>

            {/*Verticals Served*/}
            <ImageBackground source={verticalBackground} style={styles.verticalContainer} resizeMode={"stretch"}>
              <View style={styles.blackMask}>
                <View style={styles.verticalsBox}>
                  <Text style={styles.verticalHeading}>VERTICALS{'\n'}<Text
                    style={{fontFamily: "Sahar-Heavy"}}>SERVED</Text></Text>
                  <View style={styles.verticalsList}>
                    {landing_page_data !== null && landing_page_data.attributes.verticals_served.data.map((item: any, index: number) => {
                      return (
                        <div className="verticalsImage">
                          <View style={index % 2 === 0 ? styles.itemBoxEven : styles.itemBoxOdd}>
                            <Image source={item.attributes.images.file.url} style={styles.image}/>
                            <Text
                              style={index % 2 === 0 ? styles.nameEven : styles.nameOdd}>{item.attributes.name}</Text>
                          </View>
                        </div>
                      )
                    })}
                  </View>
                  <Text style={styles.vertical_served_explore_more}
                        onPress={() => navigation.navigate("VerticalServed")}>
                    Explore More
                  </Text>
                </View>
              </View>
            </ImageBackground>

            {/*Case Studies */}
            <ImageBackground source={caseStudyBackground} style={styles.caseStudyBack} resizeMode={"stretch"}>
              <Text style={styles.case}>Case <Text style={styles.studies}>Studies</Text></Text>
              <View style={styles.caseBox}>
                {landing_page_data !== null && landing_page_data.attributes.case_studies.data.map((item: any, index: number) => {
                  const backgroundImage = item.attributes.images.file.url !== "" ? item.attributes.images.file.url : caseItemBackground;
                  return (
                    <div className="caseImage" style={{backgroundImage: `url(${backgroundImage})`}} onClick={()=> this.goToCaseStudy(item)}>
                      <div className="caseBox">
                        <p className="caseName">{item.attributes.name}</p>
                        <p className={"caseDesc"}>{item.attributes.description}</p>
                      </div>
                      {/*<ImageBackground source={backgroundImage} style={styles.caseItem}>*/}
                      {/*  <Text style={styles.caseName}*/}
                      {/*        onPress={() => this.goToCaseStudy(item)}>{item.attributes.name}</Text>*/}
                      {/*  <Text style={styles.caseDesc}>{item.attributes.description}</Text>*/}
                      {/*<Text style={styles.caseDesc}>Description Line 2</Text>*/}
                      {/*</ImageBackground>*/}
                    </div>
                  )
                })}
              </View>
            </ImageBackground>

            {/* Strategic Tools */}
            <ImageBackground source={blueBackground} style={styles.strategicTools} resizeMode={"stretch"}>
              <Text style={styles.strategyHeading}><Text
                style={{fontFamily: "Sahar-Heavy-Outline"}}>OUR</Text> STRATEGIC
                TOOLS</Text>
              <div className="carousel">
                <View style={styles.strategicBox}>
                  <TouchableOpacity {...this.leftClick}>
                    <Image source={leftArrow} style={styles.control}/>
                  </TouchableOpacity>
                  <ScrollView showsHorizontalScrollIndicator={false} ref={this.scrollRef} horizontal={true}
                              style={styles.scroller}>
                    {landing_page_data !== null ? landing_page_data.attributes.strategic_tools.data.map((item: any, index: number) => {
                      return (
                        <div className="strategicItem" style={{
                          height: this.state.toolsStatus[index] ? "30vh" : "10vh"
                        }}>
                          <p className="toolName">{item.attributes.name}</p>
                          <p
                            className="toolDesc">{this.state.toolsStatus[index] ? item.attributes.description : item.attributes.landing_screen_text}</p>
                          <div className="toolClick" onClick={() => this.updateStatus(index)}>
                            <img src={arrowIcon} style={{height: "80%", width: "80%", padding: "10%"}}/>
                          </div>
                          {/*<ImageBackground source={toolCard}*/}
                          {/*                 style={this.state.toolsStatus[index] ? styles.cardLarge : styles.card}>*/}
                          {/*<TouchableOpacity style={styles.circle} onPress={()=> alert("Pressed")}></TouchableOpacity>*/}
                          {/*<TouchableOpacity style={styles.circle} onPress={() => this.updateStatus(index)}>*/}
                          {/*  <Image source={arrowIcon} style={styles.arrow}/>*/}
                          {/*</TouchableOpacity>*/}
                          {/*<Text style={styles.toolName}>{item.attributes.name}</Text>*/}
                          {/*<Text*/}
                          {/*  style={styles.toolDesc}>{this.state.toolsStatus[index] ? item.attributes.description : item.attributes.landing_screen_text}</Text>*/}
                          {/*</ImageBackground>*/}
                        </div>
                      )
                    }) : null}
                  </ScrollView>
                  <TouchableOpacity {...this.rightClick}>
                    <Image source={rightArrow} style={styles.control}/>
                  </TouchableOpacity>
                </View>
              </div>
            </ImageBackground>
            <AppFooter showSocial={true} navigation={navigation}/>
          </ScrollView>
        </div>

      </div>

    )
  }


  render() {
    const {landing_page_data} = this.state;
    // const {attributes} = landing_page_data;
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation}
                                            onClose={() => this.setState({showMenu: false})}/> : this.renderHome()

    );
  }
}

const webStyles = {
  position: "absolute",
  top: 0,
  left: 0
}

// Customizable Area Start
const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  text: {
    fontSize: wp(1),
    height: hp(60),
  },
  blueLayer: {
    // backgroundColor: configJSON.blueLayer,
    width: '100%',
    height: '100%',
  },
  backImage: {
    width: wp(100),
    height: hp(100),
    resizeMode: "contain",
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain",
  },
  deliver: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontWeight: "bold",
    fontSize: hp(9),
    letterSpacing: hp(0.4),
    color: 'rgba(255, 255, 255, 1)',
    margin: hp(6),
    marginTop: hp(15),
    position: "absolute",
    top: hp(15),
    left: hp(6),
  },
  enable: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(8),
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 1)',
    margin: hp(6),
    textAlign: "right",
    bottom: 0,
    right: 0,
    position: "absolute",
    // marginRight: wp(6),
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  menuX: {
    flexDirection: "row",
    justifyContent: "space-between",
    // margin: hp(3),
    // position: "absolute",
    // top: 0,
    // left: 0,
    padding: hp(3),
    // zIndex: 2,
    width: wp(100),
  },
  about: {
    alignItems: "center",
    justifyContent: "center",
    width: wp(100),
    height: hp(100),
    opacity: 0.800000011920929,
    backgroundColor: configJSON.colorBlack,
    paddingVertical: hp(8),
    paddingHorizontal: wp(12),
  },
  box: {
    backgroundColor: configJSON.colorMask,
    height: '100%',
    width: '100%',
    // flexDirection: "row",
    padding: hp(12),
    flex: 1,
    alignItems: "center",
  },
  welcome: {
    alignItems: "center",
  },
  welcomeText1: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(8),
    fontWeight: 'bold',
    color: configJSON.colorBlue,
  },
  welcomeText2: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(8),
    fontWeight: 'bold',
    color: configJSON.colorBlue,
  },
  description: {
    flex: 1,
    alignItems: "center",
    justifyContent:"center",
  },
  attrbuted_textlabel_paraText: {
    marginLeft: wp(4),
    opacity: 1,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'normal',
    includeFontPadding: false,
    color: 'rgba(2, 14, 53, 1)',
    fontFamily: 'Avenir-Black',
    fontSize: hp(6),
    lineHeight: hp(2.5),
  },
  attrbuted_textlabel_paraText2: {
    opacity: 1,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'normal',
    includeFontPadding: false,
    color: 'rgba(2, 14, 53, 1)',
    fontFamily: 'Avenir-Book',
    fontSize: hp(2.5),
  },
  textlabel_moreText3: {
    opacity: 1,
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'bold',
    includeFontPadding: false,
    color: 'rgba(2, 14, 53, 1)',
    fontFamily: 'Avenir-Book',
    fontSize: hp(2.5),
    position: 'absolute',
    bottom: -hp(10),
    right: 0,
    textAlign: "right",
    textDecorationLine: "underline",
  },
  verticalContainer: {
    width: wp(100),
    minHeight: hp(100),
    opacity: 0.8,
    resizeMode: "contain",
    // backgroundColor: "rgba(0,0,0,0.5)"
  },
  blackMask: {
    width: '100%',
    height: '100%',
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  verticalsBox: {
    paddingHorizontal: hp(20),
    paddingVertical: hp(4),
  },
  verticalsList: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: hp(4),
  },
  verticalHeading: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontWeight: "bold",
    fontSize: hp(6),
    color: configJSON.colorWhite,
  },
  image: {
    width: '100%',
    height: hp(25),
  },
  itemBoxEven: {
    width: wp(26),
    marginHorizontal: wp(4),
    marginVertical: hp(4),
    // backgroundColor: '#ff0000',
  },
  itemBoxOdd: {
    width: wp(26),
    marginHorizontal: wp(4),
    marginTop: hp(8),
    marginBottom: hp(4),
  },
  nameEven: {
    // position: "absolute",
    // top: '85%',
    color: configJSON.colorWhite,
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(3),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  nameOdd: {
    // position: "absolute",
    // top: '90%',
    fontSize: hp(3),
    color: configJSON.colorWhite,
    fontFamily: 'Sahar-Heavy',
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  vertical_served_explore_more: {
    marginTop: wp(2),
    // opacity: 1,
    // backgroundColor: 'transparent',
    // fontStyle: 'normal',
    // fontWeight: 'bold',
    // includeFontPadding: false,
    color: 'rgba(244, 133, 39, 1)',
    fontFamily: 'Avenir-Medium',
    fontSize: hp(2.8),
    // position: 'absolute',
    // left: wp(12.2),
    // bottom: hp(6),
    textAlign: "left",
    marginLeft: wp(4),
    // padding: hp(3),
    textDecorationLine: "underline",
  },
  caseStudyBack: {
    width: wp(100),
    minHeight: hp(80),
    backgroundColor: configJSON.colorBlack,
    paddingHorizontal: wp(12),
    paddingVertical: hp(8),
    alignItems: "center"
  },
  case: {
    fontFamily: 'Sahar-Heavy-Outline',
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: hp(6),
    color: configJSON.colorWhite,
    textAlign: "left",
    alignSelf: "flex-start",
  },
  studies: {
    fontFamily: 'Sahar-Heavy',
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: hp(6),
    color: configJSON.colorWhite,
  },
  caseBox: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: hp(4),
    justifyContent: "center",
  },
  caseItem: {
    width: wp(35),
    height: hp(30),
    resizeMode: "cover",
    // justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    paddingVertical: wp(1.5),
  },
  logo: {
    width: wp(18),
    height: hp(6),
    resizeMode: "contain",
  },
  logoLarge: {
    width: wp(18),
    height: hp(8),
    resizeMode: "contain",
  },
  caseName: {
    fontFamily: 'Sahar-Heavy',
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: hp(6),
    color: configJSON.colorWhite,
    // marginTop: hp(3),
  },
  caseDesc: {
    fontSize: hp(2),
    color: configJSON.colorWhite,
    paddingHorizontal: wp(2),
    paddingVertical: hp(1),
    textAlign: "center",
  },
  strategicTools: {
    paddingVertical: hp(8),
    // paddingHorizontal: wp(8),
    width: wp(100),
    alignItems: "center",
    justifyContent: "center",
  },
  strategicBox: {
    flexDirection: "row",
    marginTop: hp(4),
    // padding: wp(2),
    flexWrap: 'wrap',
    justifyContent: "center",
    alignItems: "center",
    // alignSelf:"center"
  },
  strategyHeading: {
    fontFamily: 'Sahar-Heavy',
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: hp(6),
    color: configJSON.colorWhite,
  },
  stratItemBox: {},
  card: {
    width: wp(20),
    height: hp(16),
    paddingHorizontal: wp(1),
    paddingVertical: hp(4),
    margin: wp(1),
    borderRadius: hp(1),
    resizeMode: "contain",
    overflow: "hidden"
  },
  cardLarge: {
    width: wp(20),
    height: hp(45),
    paddingHorizontal: wp(1),
    paddingVertical: hp(4),
    margin: wp(1),
    borderRadius: hp(1),
    resizeMode: "contain",
    overflow: "hidden"
  },
  circle: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: wp(3),
    width: wp(3),
    backgroundColor: configJSON.colorBlue,
    borderTopLeftRadius: wp(100),
  },
  toolName: {
    alignSelf: "center",
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(2.5),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  toolDesc: {
    marginTop: hp(1),
    textAlign: "center",
    fontSize: hp(1.8),
    color: configJSON.colorWhite,
  },
  arrow: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: wp(1),
    height: wp(1),
    padding: wp(1)
  },
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  scroller: {
    width: wp(80),
    paddingBottom: hp(3),
  },
  control: {
    height: hp(6),
    width: wp(6),
  },
  absolute: {
    position: "absolute",
    top: 0,
    left: 0,
  }
});
// Customizable Area End
