export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const topNavBackground = require('../assets/group_bg_main.png');
export const verticalBackground = require('../assets/vertical_background.png');
export const caseStudyBackground = require('../assets/skip_bg_main.png');
export const saintsArtLogo = require('../assets/skip_Logo.png');
export const caseItemBackground = require('../assets/skip_bg.png');
export const blueBackground = require('../assets/blue_back.png');
export const toolCard = require('../assets/skip_shape gray.png');
export const arrowIcon = require('../assets/skip_arrow.png');
export const menuOpen = require('../assets/skip_menu.png');
export const menuClose = require('../assets/skip_close.png');
export const menuBackground = require('../assets/group_image.png');
export const leftArrow = require("../assets/left-arrow.png");
export const rightArrow = require("../assets/right-arrow.png");
export const logo = require("../assets/saintsArt.png");

