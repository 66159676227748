import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';

import {
  StyleSheet,
  Text,
  Image,
  ScrollView,
  ImageBackground,
  TouchableOpacity, Dimensions, View, Platform
} from "react-native";
import LandingPageController, {
  configJSON,
  Props
} from "./StrategicArtController";
import {
  dots,
  menuBackground,
  menuClose,
  menuOpen,
  saintsArtLogo, StrategicArtBackground,
} from "./assets";
import StrategicArtController from "./StrategicArtController";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class StrategicArt extends StrategicArtController {


  constructor(props: Props) {
    super(props);
  }

  renderHome = () => {
    const {navigation} = this.props;
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={StrategicArtBackground} style={styles.backImage}>
            <View>
              {/*<Text style={styles.heading}>STRATEGIC</Text>*/}
              <Text style={styles.heading}>STRATEGIC</Text>
              <Text style={styles.heading1}>ART</Text>
            </View>
            <View style={styles.contentBox}>
              <Text style={styles.content}>
                {(this.state.apiData !== null && this.state.apiData !== []) ? this.state.apiData.attributes.description : null}
                {/*{'\n'}In this era of communication when the leader admires innovation and followers decides*/}
                {/*differentiation it*/}
                {/*becomes mandatory that the strategy is clutter breaking and sustaining for the desired Market Value. We*/}
                {/*help Brands deliver the desired Business Strategy & Market Intelligence along with diligent Market Mapping*/}
                {/*& Research (For the desired Market in terms of prevailg socio-cultural aspects). The detailed outlining of*/}
                {/*the strategic initiatives does involve Case Study generation, Suggestive Growth Plans, Market Penetration*/}
                {/*including Strategic views on Product Mix and Mergers & Acquisitions.*/}
              </Text>
              {/*<Text style={styles.content}>*/}
              {/*  {'\n'}{'\n'}At SAINTS ART, we blend the right elements of strategic communications that include pragmatic*/}
              {/*  thinking,*/}
              {/*  innovative solutions, mitigation measures and sustainable approaches. The right analysis and audit is what*/}
              {/*  we begin with. Understanding the market, the industry, the client, his brand- its vision and mission helps*/}
              {/*  us define our strategy which is fail-proof.*/}
              {/*</Text>*/}
            </View>
          </ImageBackground>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>

    )
  }


  render() {
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation}
                                            onClose={() => this.setState({showMenu: false})}/> : this.renderHome()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  text: {
    fontSize: wp(1),
    height: hp(60),
  },
  backImage: {
    width: wp(100),
    minHeight: windowWidth<600 ?"96vh" :"100vh",
    resizeMode: "contain",
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  heading: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
    textAlign: "center",
    marginTop: windowWidth < 600 ? hp(20) : hp(15),
  },
  heading1: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(12),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
    textAlign: "center",
  },
  contentBox: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: wp(12)
  },
  content: {
    fontSize: hp(3),
    color: configJSON.colorWhite,
    fontFamily: "Avenir-Medium",
    letterSpacing: wp(0.1),
    textAlign: "center",
    marginBottom: hp(6),
  },
  dotsImage: {
    position: "absolute",
    width: wp(1),
    height: hp(2),
    top: 0,
  }
});
// Customizable Area End
