import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showMenu: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutPageController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      showMenu: false,
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      runEngine.debugLog("API Message Recived", message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
  }

  menuOpenProps = {
    onClick: () => {
      this.setState({showMenu: true});
    }
  }
  menuCloseProps = {
    onPress: () => {
      this.setState({showMenu: false});
    }
  }
  logoProps = {
    onClick: () => {
      this.props.navigation.navigate("Home");
    }
  }

  navigationLinkPressed = (path: string) => {
    let to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), path);
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.setState({showMenu: false})
    this.send(to);
  }
  // Customizable Area End
}
