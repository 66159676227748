import React from "react";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import ContentManagementController, {Props, configJSON} from "./ContentManagementController";
import {StyleSheet, Text, Image, View, ScrollView, ImageBackground, TouchableOpacity, Dimensions} from "react-native";
// Customizable Area Start
import AppFooter from "../../../components/src/AppFooter";
import {saintsArtLogo, menuOpen, menuBackground, menuClose, contentBanner} from "./assets";
import NavigationMenu from "../../../components/src/NavigationMenu";
// Customizable Area End
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;


export default class ContentManagementPage extends ContentManagementController {

  static instance: ContentManagementPage;

  constructor(props: Props) {
    super(props);
    ContentManagementPage.instance = this;
  }

  renderMain = () => {
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={contentBanner} style={styles.contentContainer} resizeMode={"stretch"}>
            <Text style={styles.content_management_text_one}>CONTENT
              <Text>{'\n'}MANAGEMENT</Text>
            </Text>
            {/*<Text style={styles.content_management_text_two}>*/}
            {/*  Changing market dynamics and cluttered messaging demands content which may further support long-term as well*/}
            {/*  as short-term objectives of Brands. While content may vary from being a part of internal communication to*/}
            {/*  external in nature, our technical content writers with their rich industry expertise are able to drive the*/}
            {/*  complete content gamut of organizations ranging from startups.{'\n'}{'\n'}{'\n'}Our filtered team of content*/}
            {/*  writers from varied industry may support highly technical to simple communication objectives of*/}
            {/*  organizations including White Papers, Case Studies, Marketing communication modules, Spokesperson modules*/}
            {/*  etc.*/}
            {/*</Text>*/}
            <Text style={styles.content_management_text_two}>
              {(this.state.apiData!==null && this.state.apiData!==[]) ? this.state.apiData.attributes.description : null}
            </Text>
          </ImageBackground>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>
    )
  }

  render() {
    const {navigation} = this.props;
    const _this = this;
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation} onClose={()=> this.setState({showMenu: false})} />: this.renderMain()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1
  },
  contentContainer: {
    width: wp(100),
    minHeight: "96vh",
  },
  contentLayer: {},
  content_management_text_one: {
    fontFamily: 'Sahar-Heavy',
    fontSize: windowWidth<600 ? hp(5) :hp(10),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
    textAlign: 'center',
    letterSpacing: hp(1),
    marginTop: windowWidth < 600 ? hp(10) : hp(15),
  },
  content_management_text_two: {
    fontSize: hp(3),
    color: configJSON.colorWhite,
    fontFamily: "Avenir-Medium",
    letterSpacing: wp(0.1),
    fontWeight: 'normal',
    padding: wp(3),
    opacity: 1,
    backgroundColor: 'transparent',
    textAlign: 'left',
    zIndex: 0,
    paddingHorizontal: wp(12),
  }
});
// Customizable Area End
