import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';
import {StyleSheet, Text, Image, View, ScrollView, ImageBackground, TouchableOpacity, Dimensions} from "react-native";
import AboutPageController, {Props, configJSON} from "./AboutPageController";
// Customizable Area Start
import {
  imgVerticalServedBackground,
  imgBrandPhilosophyBackgroup,
  aboutBackground,
  vText,
  groupOverview
} from "./assets";
import {Icon} from "react-native-elements";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import {menuBackground, menuClose, menuOpen, saintsArtLogo} from "../../LandingPage/src/assets";
import Header from "../../../components/src/Header";
// Customizable Area End

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const hp = (x: number | string) => {
  // const windowWidth = Dimensions.get("window").width;
  // const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  // const windowWidth = Dimensions.get("window").width;
  // const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class AboutPage extends AboutPageController {

  constructor(props: Props) {
    super(props);
  }

  renderMain = () => {
    return (
      <div>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={aboutBackground} style={styles.aboutContainer}>
            <Text style={styles.about_text}>About</Text>
            <View style={styles.about_text_group}>
              <View style={styles.view_line}></View>
              <Text style={styles.about_explore_text}>
                Explore the Art of{'\n'}Communication{'\n'}with SAINTS ART
              </Text>
            </View>
          </ImageBackground>
          <ImageBackground source={groupOverview} style={styles.overBack} resizeMode={"stretch"}>
            <TouchableOpacity style={styles.exploreMore} onPress={() => this.props.navigation.navigate("Overview")}>
              <Text style={styles.moreText}>Explore More</Text>
            </TouchableOpacity>
          </ImageBackground>
          <View style={styles.brand_container}>
            <View style={styles.brand_background_one}>
              <Text style={styles.brand_background_one_text}>Brand
                <Text>{'\n'}Philosophy</Text>
              </Text>
            </View>
            <View style={styles.brand_background_two}>
              <Image source={imgBrandPhilosophyBackgroup} style={styles.brand_philosophy_img}/>
              <TouchableOpacity onPress={() => this.props.navigation.navigate("BrandPhilosophy")}>
                <Text style={styles.textlabel_moreText2}>EXPLORE MORE</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.vertical_served_container}>
            <Image
              style={styles.vertical_served_bg}
              source={imgVerticalServedBackground}
            />
            <Image
              style={styles.vertical_served_bg1}
              source={vText}
            />

            <Text style={styles.vertical_served_text}>Verticals{'\n'}
              <Text>Served</Text>
            </Text>
            <TouchableOpacity onPress={() => this.props.navigation.navigate("VerticalServed")}>
              <Text style={styles.vertical_served_moretext}>EXPLORE
                <Text> MORE</Text>
              </Text>
            </TouchableOpacity>
          </View>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </div>
    )
  }

  render() {
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation}
                                            onClose={() => this.setState({showMenu: false})}/> : this.renderMain()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  root: {
    flex: 1
  },
  aboutContainer: {
    width: wp(100),
    height: hp(60),
    resizeMode: "contain",
  },
  aboutLayer: {
    // backgroundColor: configJSON.aboutLayer,
    // width: '100%',
    // height: '100%',
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(2),
    padding: hp(2),
    position: "absolute",
    zIndex: 2
  },
  about_text: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(24),
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 1)',
    textAlign: "center",
    marginLeft: wp(10),
    marginTop: windowWidth < 600 ? hp(18) : hp(12),
  },
  about_text_group: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute',
    bottom: 0,
  },
  view_line: {
    width: wp(0.1),
    height: wp(5),
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  about_explore_text: {
    fontFamily: 'Sahar-Regular',
    letterSpacing: wp(0.3),
    fontSize: hp(3),
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 1)',
    margin: hp(2),
    textAlign: "left",
  },
  group_section2Group: {
    width: wp(100),
    height: hp(50),
    resizeMode: "contain",
    flexDirection: "row",
    backgroundColor: 'rgba(168, 174, 183, 1)'
  },
  OText_image: {
    height: hp(45),
    width: wp(8),
    resizeMode: "contain"
  },
  group_imacGroup: {
    width: wp(100),
    height: hp(50),
    opacity: 1,
    position: 'absolute',
    textAlign: 'center',
    justifyContent: 'center'
  },
  textlabel_overText: {
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(255, 255, 255, 1)',
    textAlign: 'center',
    fontFamily: 'Avenir-Medium',
    fontSize: wp(3)
  },
  image_imacImage: {
    width: wp(100),
    height: hp(40),
    opacity: 1,
    textAlign: 'center',
    position: 'absolute',
    resizeMode: 'contain',
  },
  textlabel_moreText3: {
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(255, 255, 255, 1)',
    textAlign: 'right',
    fontFamily: 'Avenir-Black',
    fontSize: wp(1),
    opacity: 1,
    position: 'absolute',
    bottom: wp(0),
    right: wp(0),
    padding: wp(3)
  },
  brand_container: {
    width: wp(100),
    height: hp(50),
    resizeMode: "contain",
    flexDirection: "row"
  },
  brand_background_one: {
    backgroundColor: 'rgba(2, 14, 53, 1)',
    flex: 1,
    textAlign: 'center',
    justifyContent: 'center',
  },
  brand_background_one_text: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontWeight: "bold",
    fontSize: hp(9),
    letterSpacing: hp(0.4),
    color: 'rgba(255, 255, 255, 1)',
  },
  brand_background_two: {
    flexDirection: "column",
    backgroundColor: 'rgba(255, 255, 255, 1)',
    flex: 1
  },
  brand_philosophy_img: {
    width: hp(25),
    height: hp(25),
    margin: wp(4),
    alignSelf: "center"
  },
  textlabel_moreText2: {
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: 'rgba(2, 14, 53, 1)',
    textAlign: 'center',
    fontFamily: 'Avenir-Black',
    fontSize: wp(1.6),
    textDecorationLine: "underline",
  },
  vertical_served_container: {
    width: wp(100),
    height: hp(60),
    resizeMode: "contain",
    backgroundColor: 'rgba(2, 14, 53, 1)'
  },
  vertical_served_bg: {
    width: wp(80),
    height: hp(60)
  },
  vertical_served_bg1: {
    width: wp(80),
    height: hp(60),
    position: "absolute",
    resizeMode: "cover"
  },
  vertical_served_text: {
    fontFamily: 'Sahar-Heavy',
    fontWeight: "bold",
    fontSize: hp(8),
    letterSpacing: hp(0.4),
    color: 'rgba(255, 255, 255, 1)',
    marginRight: hp(17),
    position: 'absolute',
    top: wp(10),
    right: -wp(5)
  },
  vertical_served_moretext: {
    fontFamily: 'Avenir-Medium',
    fontWeight: "bold",
    fontSize: wp(1.6),
    letterSpacing: hp(0.4),
    color: 'rgba(255, 255, 255, 1)',
    position: 'absolute',
    bottom: 0,
    right: 0,
    marginRight: hp(17),
    padding: hp(5),
    textDecorationLine: "underline",
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },

  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  overBack: {
    width: wp(100),
    height: hp(100),

  },
  exploreMore: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: wp(10),
    marginBottom: hp(10),
  },
  moreText: {
    textAlign: 'center',
    fontFamily: 'Avenir-Medium',
    fontSize: wp(1.6),
    color: configJSON.colorWhite,
    textTransform: "uppercase",
    textDecorationLine: "underline",
    fontWeight: "bold",
  }
});
