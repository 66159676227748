import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';
import PRArtController, { Props, configJSON } from "./PRArtController";
import {StyleSheet, Text, Image, View, ScrollView, ImageBackground, TouchableOpacity, Dimensions} from "react-native";
// Customizable Area Start
import {
  menuBackground,
  menuClose,
  menuOpen,
  PRBackground,
  saintsArtLogo
} from "./assets";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";


const windowWidth = Dimensions.get("window").width;

// Customizable Area End

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class PRArtPage extends PRArtController {

  constructor(props: any) {
    super(props);
  }

  renderMain = () => {
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={PRBackground} style={styles.brandContainer} resizeMode={"stretch"}>
            <View style={styles.container}>
              <Text style={styles.brand_header_text_one}>PR
                <Text style={styles.brand_header_text_two}>{'\n'}ART</Text>
              </Text>
              {/*<Text style={styles.content}>*/}
              {/*  {'\n'}{'\n'}At SAINTS ART, we strap up publicity and positioning to create a buzz and a build brand and add value to*/}
              {/*  the ones we serve. We garner attention, drive conversations, and generate stories that need to be told in*/}
              {/*  some of the top traditional media outlets. We have a team of experienced strategists who craft ideas,*/}
              {/*  polish them and make it real.{'\n'}{'\n'}Having served leading brands from varied verticals including*/}
              {/*  market leaders we have been instrumental in delivering the desired Brand value and recall to the*/}
              {/*  corporations. Our extensive network and capable team of PR experts has added to the market strength our*/}
              {/*  clients.{'\n'}{'\n'}Key services include Media Relations & Media Representation, Media Relations, Content*/}
              {/*  Management, Digital Mapping for online reputation management, Social Media Handling for consumer*/}
              {/*  engagement, Media Advisory, Crisis Management and CSR Delivery.*/}
              {/*</Text>*/}
              {/*<Text style={styles.subHeading}>*/}
              {/*  Crisis Communication as part of PR*/}
              {/*</Text>*/}
              {/*<Text style={styles.content}>*/}
              {/*  We bring to the table a team of crisis management experts who with their outstanding skills,*/}
              {/*  matchless experience and agility can not only anticipate but tackle challenging situations like a pro. Any*/}
              {/*  defamatory remarks, trolls or a negative story, we are here to respond proactively and garner positive*/}
              {/*  traction, thus turning the tables. Our Crisis Management capabilities include: Threat assessments,*/}
              {/*  Proactive Planning, Drafting Q&A for public dissemination, Press conferences, Broadcast and print media*/}
              {/*  training, Onsite support, Social media monitoring and response, Reputation management*/}
              {/*</Text>*/}
              {/*<Text style={styles.subHeading}>*/}
              {/*  Media Event Management*/}
              {/*</Text>*/}
              {/*<Text style={styles.content}>*/}
              {/*  From a flashy product launch, key business announcements to international level forums, SAINTS ART can*/}
              {/*  manage the entire event, from the venue suggestion to conceptualization and media management. We make*/}
              {/*  sure we never miss the target media, the messaging is crisp and clear, and offer full support to make*/}
              {/*  the event a success. What follows is post event evaluations and great coverage.*/}
              {/*</Text>*/}
              <Text style={styles.content}>
                {(this.state.apiData!==null && this.state.apiData!=="") ? this.state.apiData?.attributes.description : ""}
              </Text>

            </View>
          </ImageBackground>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>
    )
  }

  render() {
    const {navigation} = this.props;
    const _this = this;
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation} onClose={()=> this.setState({showMenu: false})} />: this.renderMain()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  root: {
    flex: 1
  },
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  container: {
    paddingHorizontal: wp(12),
    paddingVertical: wp(2),
    marginTop: windowWidth < 600 ? hp(20) : hp(15),
  },
  brand_header_text_one: {
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
  },
  brand_header_text_two: {
    fontFamily: 'Sahar-Heavy',
    fontSize: hp(12),
    fontWeight: 'bold',
    color: configJSON.colorWhite,
  },
  content: {
    fontSize: hp(3),
    color: configJSON.colorWhite,
    fontFamily: "Avenir-Medium",
    letterSpacing: wp(0.1),
    // fontFamily: 'Sahar-Heavy',
    textAlign: "left",
  },
  brandContainer: {
    width: wp(100),
    minHeight: windowWidth<600 ? '96vh' : "200vh",
    paddingBottom: hp(25),
    //resizeMode: "contain",
    //backgroundColor: "rgba(2, 14, 53, 1)",
  },
  subHeading: {
    fontFamily: 'Sahar-Heavy',
    fontSize: wp(2),
    fontWeight: 'bold',
    marginTop: hp(10),
    marginBottom: hp(4),
    color: configJSON.colorPrArtSubHeading
  }
  // brandLayer: {},
  // brand_header_text_one: {
  //   fontFamily: 'Sahar-Heavy-Outline',
  //   fontSize: hp(10),
  //   fontWeight: 'bold',
  //   color: 'rgba(0, 77, 241, 1)',
  //   textAlign: 'center'
  // },
  // brand_header_text_two: {
  //   fontFamily: 'Sahar-Heavy',
  //   fontSize: hp(10),
  //   fontWeight: 'bold',
  //   color: 'rgba(0, 77, 241, 1)',
  // },
  // brand_container_two_text: {
  //   fontFamily: 'Sahar-Heavy-Outline',
  //   fontSize: hp(3),
  //   fontWeight: 'normal',
  //   color: configJSON.colorWhite,
  //   padding: wp(3),
  //   opacity: 1,
  //   backgroundColor: 'transparent',
  //   textAlign: 'left',
  //   paddingHorizontal: wp(12),
  // },
  // brandContainerTwo: {
  //   alignItems: 'center',
  //   alignSelf: 'center',
  //   opacity: 1,
  // },
  // viewcircle_back: {
  //   width: wp(18),
  //   height: wp(14),
  //   backgroundColor: 'rgba(0, 77, 241, 1)',
  //   opacity: 0.2000000029802322,
  //   borderRadius: wp(50),
  //   borderWidth: wp(1),
  //   position: 'absolute',
  //   bottom: 0,
  // },
  // viewcircle_front: {
  //   width: wp(18),
  //   height: wp(14),
  //   backgroundColor: 'rgba(0, 77, 241, 1)',
  //   borderRadius: wp(50),
  //   borderWidth: wp(1),
  //   position: 'absolute',
  //   bottom: 0
  // },
  // brand_circle_text: {
  //   fontFamily: 'Sahar-Heavy',
  //   fontSize: hp(3),
  //   fontWeight: 'bold',
  //   color: 'rgba(255, 255, 255, 1)',
  //   textAlign: 'center',
  //   position: 'absolute',
  //   bottom: 0,
  //   right: 0,
  //   left: 0
  // }
});
// Customizable Area End
