import React from "react";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import BrandPhilosophyController, {Props, configJSON} from "./BrandPhilosophyController";
import {StyleSheet, Text, Image, View, ScrollView, ImageBackground, TouchableOpacity, Dimensions} from "react-native";
// Customizable Area Start
import AppFooter from "../../../components/src/AppFooter";
import {
  saintsArtLogo,
  menuOpen,
  menuBackground,
  menuClose,
  brandBanner,
  brandBackground,
  blueCircle,
  blueBackCircle
} from "./assets";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;
// Customizable Area End

export default class BrandPhilosophyPage extends BrandPhilosophyController {

  static instance: BrandPhilosophyPage;

  constructor(props: Props) {
    super(props);
    BrandPhilosophyPage.instance = this;
  }

  renderMain = () => {
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={brandBackground} style={styles.brandContainer} resizeMode={"stretch"}>
            <Text style={styles.brand_header_text_one}>BRAND
              <Text style={styles.brand_header_text_two}>{'\n'}PHILOSOPHY</Text>
            </Text>
            <Text style={styles.brand_container_two_text}>
              {this.state.apiData !== null ? this.state.apiData.attributes.description : ""}
            </Text>
            <Text style={styles.brand_circle_text}>Brand {'\n'} Philosophy</Text>
            <Image style={styles.blueCircle} source={blueCircle}/>
            <Image style={styles.blueBackCircle} source={blueBackCircle}/>
          </ImageBackground>
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>
    )
  }

  render() {
    const {navigation} = this.props;
    const _this = this;
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation}
                                            onClose={() => this.setState({showMenu: false})}/> : this.renderMain()
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1,
    overflow: "hidden",
  },
  brandContainer: {
    width: wp(100),
    minHeight: windowWidth<600 ? "96vh" :hp(150),
    overflow: "hidden"
    //resizeMode: "contain",
    //backgroundColor: "rgba(2, 14, 53, 1)",
  },
  brandLayer: {},
  brand_header_text_one: {
    marginTop: hp(12),
    fontFamily: 'Sahar-Heavy-Outline',
    fontSize: windowWidth<600 ? hp(6) : hp(12),
    fontWeight: 'bold',
    color: 'rgba(0, 77, 241, 1)',
    textAlign: 'center'
  },
  brand_header_text_two: {
    fontFamily: 'Sahar-Heavy',
    fontSize: windowWidth<600 ? hp(6) : hp(12),
    fontWeight: 'bold',
    color: 'rgba(0, 77, 241, 1)',
  },
  brand_container_two_text: {
    fontFamily: 'Avenir-Medium',
    fontSize: hp(3),
    fontWeight: 'normal',
    color: configJSON.colorWhite,
    padding: wp(3),
    opacity: 1,
    backgroundColor: 'transparent',
    textAlign: 'left',
    paddingHorizontal: wp(12),
    letterSpacing: wp(0.2),
  },
  brandContainerTwo: {
    alignItems: 'center',
    alignSelf: 'center',
    opacity: 1,
  },
  viewcircle_back: {
    width: wp(18),
    height: wp(14),
    backgroundColor: 'rgba(0, 77, 241, 1)',
    opacity: 0.2000000029802322,
    borderRadius: wp(50),
    borderWidth: wp(1),
    position: 'absolute',
    bottom: 0,
  },
  viewcircle_front: {
    width: wp(18),
    height: wp(14),
    backgroundColor: 'rgba(0, 77, 241, 1)',
    borderRadius: wp(50),
    borderWidth: wp(1),
    position: 'absolute',
    bottom: 0
  },
  brand_circle_text: {
    fontFamily: 'Avenir-Medium',
    fontSize: hp(4.5),
    fontWeight: 'bold',
    color: 'rgba(255, 255, 255, 1)',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    marginBottom: 10,
  },
  blueCircle: {
    width: wp(30),
    height: hp(30),
    resizeMode: "contain",
    position: "absolute",
    bottom: -hp(10),
    alignSelf: "center",
    zIndex: 1,
  },
  blueBackCircle: {
    width: wp(40),
    height: hp(40),
    resizeMode: "contain",
    position: "absolute",
    bottom: -hp(13.3),
    alignSelf: "center",
    zIndex: 0,
  },
});
// Customizable Area End
