import React from "react";
import {
  heightPercentageToDP, widthPercentageToDP
} from 'react-native-responsive-screen';

import {
  StyleSheet,
  Text,
  Image,
  ScrollView,
  ImageBackground,
  TouchableOpacity, Dimensions, View,
} from "react-native";
import CaseStudiesPageController, {
  configJSON,
  Props
} from "./CaseStudiesPageController";
import {
  backgroundContact, caseBackground, leftPattern,
  menuBackground,
  menuClose,
  menuOpen, rightPattern,
  saintsArtLogo,
} from "./assets";

import {caseItemBackground} from "../../LandingPage/src/assets";
import AppFooter from "../../../components/src/AppFooter";
import NavigationMenu from "../../../components/src/NavigationMenu";
import Header from "../../../components/src/Header";

const windowWidth = Dimensions.get("window").width;

const hp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return heightPercentageToDP(x) * 0.4;
  else
    return heightPercentageToDP(x);
}

const wp = (x: number | string) => {
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  if (windowWidth < windowHeight)
    return widthPercentageToDP(x);
  else
    return widthPercentageToDP(x);
}

export default class CaseStudiesPage extends CaseStudiesPageController {

  static instance: CaseStudiesPage;

  constructor(props: Props) {
    super(props);
    CaseStudiesPage.instance = this;
  }

  renderHome = () => {
    const {navigation} = this.props;
    const {caseStudies} = this.state;
    return (
      <>
        <Header navigation={this.props.navigation} menuProps={this.menuOpenProps} logoProps={this.logoProps}/>
        <ScrollView style={styles.root}>
          <ImageBackground source={caseBackground} style={styles.backImage} resizeMode={"stretch"}>
            <Text style={styles.caseText}>CASE{'\n'}STUDIES</Text>
          </ImageBackground>
          {caseStudies !== null ? caseStudies.map((item: any, index: number) => {
            const image = (item.attributes.background_image.file.url !== "" && item.attributes.background_image.file.url !== null) ? item.attributes.background_image.file.url : caseItemBackground;
            const isEven = index % 2 === 0 ? true : false;
            return (
              <ImageBackground source={image} style={styles.backImage} resizeMode={"stretch"} key={item.id}>
                <View style={[styles.caseBox, {alignItems: isEven ? "flex-end" : "flex-start"}]}>
                  <Text style={styles.caseName}>{item.attributes.name}</Text>
                  <Text style={[styles.caseDesc, {textAlign: isEven ? "right": "left"}]}>
                    {item.attributes.landing_screen_text}
                  </Text>
                  <TouchableOpacity style={styles.detailBox} onPress={()=>{
                    this.goToCaseStudy(item)
                  }}>
                    <Text style={styles.detail}>Detailed Case Study</Text>
                  </TouchableOpacity>
                </View>
              </ImageBackground>
            )
          }) : null}
          <AppFooter navigation={this.props.navigation}/>
        </ScrollView>
      </>
    )
  }

  render() {
    return (
      this.state.showMenu ? <NavigationMenu navigation={this.props.navigation} onClose={()=> this.setState({showMenu: false})} />: this.renderHome()
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  menuBox: {
    height: hp(100),
    width: wp(100),
    backgroundColor: 'rgba(0,0,0,1)',
    overflow: "hidden"
  },
  menuBack: {
    position: "absolute",
    height: hp(200),
    width: wp(120),
    left: -wp(60),
    resizeMode: "cover"
  },
  menu: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: hp(3),
  },
  logo: {
    width: wp(20),
    height: hp(10),
    resizeMode: "contain"
  },
  menuOpen: {
    width: wp(4),
    height: hp(4),
    margin: wp(1.5),
    resizeMode: "contain"
  },
  navBox: {
    alignSelf: "center",
    marginLeft: wp(15),
  },
  navTitle: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  navTitle1: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(7),
    textTransform: "uppercase",
    fontWeight: "bold",
    marginVertical: hp(1.4),
  },
  root: {
    flex: 1,
  },
  backImage: {
    width: wp(100),
    height: hp(80),
  },
  caseText: {
    fontFamily: 'Sahar-Heavy-Outline',
    color: configJSON.colorWhite,
    fontSize: hp(15),
    textTransform: "uppercase",
    marginTop: windowWidth < 600 ? hp(20) : hp(15),
    marginLeft: wp(10),
    letterSpacing: wp(0.5),
  },
  caseBox: {
    padding: wp(8),
    height: '100%',
    justifyContent: "center",
  },
  caseName: {
    fontFamily: 'Sahar-Heavy',
    color: configJSON.colorWhite,
    fontSize: hp(15),
    textTransform: "uppercase",
  },
  caseDesc: {
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorWhite,
    fontSize: hp(3),
    marginTop: hp(3),
    letterSpacing: wp(0.2),
  },
  detailBox: {},
  detail: {
    fontFamily: 'Avenir-Medium',
    color: configJSON.colorWhite,
    fontSize: hp(4),
    textTransform: "uppercase",
    marginTop: hp(5),
    textDecorationLine: "underline"
  },
});

// Customizable Area End
