export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const menuOpen = require("../assets/skip_menu.png");
export const menuClose = require('../assets/skip_close.png');
export const menuBackground = require('../assets/group_image.png');
export const saintsArtLogo = require("../assets/skip_Logo.png");
export const aboutBackgroup = require("../assets/About_group_image.png");
export const imgAboutBF = require("../assets/about_fill.png");
export const macOverview = require("../assets/group_mac_overview.png");
export const imgOverBG = require("../assets/over_bg.png");
export const imgCircleOne = require("../assets/view_circle.png");
export const imgCircleTwo = require("../assets/view_circle1.png");
export const imgBrandLines = require("../assets/skip_lines.png");
export const imgCircleBack = require("../assets/viewcircle_back.png");
export const imgCircleFront = require("../assets/viewcircle_front.png");
export const imgVerticalServedBackground= require("../assets/vertical_served_backgroup.png");
export const imgBrandPhilosophyBackgroup = require('../assets/skip_brand.png');
export const aboutBackground = require('../assets/About_group_image.png');
export const vText = require('../assets/skip_vshape.png');
export const groupOverview = require('../assets/group_mac_overview.png');

export const brandBanner = require("../assets/brand.png");
export const verticalBanner = require("../assets/Verticals.png");

export const dots = require("../assets/skip_dots.png");
export const expertiseTopNav = require("../assets/expertise_top_nav.png");
export const strategicArtBanner= require("../assets/strategic_art_banner.png");
export const prArtBanner = require("../assets/prart_banner.png");
export const contentManagementBanner = require("../assets/content_management_banner.png");
export const creativeBanner = require("../assets/creative_banner.png");
export const PRBackground = require('../assets/PR.png');
export const StrategicArtBackground = require('../assets/Strategic.png');
export const creativeBackground = require('../assets/Creative.png');
export const careersRedBackground = require("../assets/careers.png");
export const careersBlueBackground = require("../assets/careers-2.png");

//Contact Screen
export const backgroundContact = require('../assets/skip_bg pattern.png');
export const leftPattern = require('../assets/skip_patternleft.png');
export const rightPattern = require('../assets/skip_patternright.png');
export const emailIcon = require("../assets/skip_email.png");
export const mobileIcon = require('../assets/skip_mobile.png');
export const addressIcon1 =require('../assets/skip_icon1.png');
export const addressIcon2 = require('../assets/skip_icon2.png');

// Case Studies Screen
export const caseBackground  =  require('../assets/case-1.png');

export const strategicBanner = require("../assets/Strategic.png");
export const contentBanner = require("../assets/Content.png");

export const caseBanner = require("../assets/case_banner.png");
export const caseCardOne = require("../assets/case_group_image1.png");
export const caseCardTwo = require("../assets/case_group_image2.png");
export const caseCardThree = require("../assets/case_group_image3.png");

export const filmProductionBanner = require("../assets/film.png");
export const brandBackground = require("../assets/group_section1.png");
export const blueCircle = require("../assets/viewcircle_front.png");
export const blueBackCircle = require("../assets/viewcircle_back.png");

// Career Screen
export const card1 = require("../assets/1.png")
export const card2 = require("../assets/2.png")
export const card3 = require("../assets/3.png")
export const card4 = require("../assets/4.png")
export const card5 = require("../assets/5.png")
export const card6 = require("../assets/6.png")
export const card7 = require("../assets/7.png")
export const card8 = require("../assets/8.png")

